import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const ArrowRight: React.FC<SvgProps> = (props) => {
  return (
    <Icon viewBox="0 0 12 12" {...props}>
      <path
        d="M6.8197 1.90088L10.9188 5.99994M10.9188 5.99994L6.8197 10.099M10.9188 5.99994H1.08105"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
