import { cloneElement, ReactElement, useMemo } from 'react'
import { Icon, IconButton } from '@lounge-fe/ui-kit'
import { SocialNetwork } from '@/types/SocialNetwork'
import { SocialLinksProps } from './types'

const socialNetworkIcons: Record<SocialNetwork, ReactElement> = {
  facebook: <Icon.FacebookAlt aria-label="Facebook" />,
  twitter: <Icon.Twitter aria-label="Twitter" />,
  instagram: <Icon.Instagram aria-label="Instagram" />,
  pinterest: <Icon.Pinterest aria-label="Pinterest" />,
  youtube: <Icon.Youtube aria-label="Youtube" />,
  tiktok: <Icon.TikTok aria-label="TikTok" />,
  linkedin: <Icon.LinkedIn aria-label="LinkedIn" />,
}

export const SocialLinks = ({ socialUrls }: SocialLinksProps) => {
  const enabledSocialUrls: [SocialNetwork, string][] = useMemo(
    () =>
      Object.entries(socialUrls).filter(([, url]) => Boolean(url)) as [
        SocialNetwork,
        string,
      ][],
    [socialUrls],
  )
  return (
    <div className="flex gap-x-2 items-center">
      {enabledSocialUrls.map(([socialNetwork, url]) => (
        <IconButton
          as="a"
          key={socialNetwork}
          href={url}
          aria-label={socialNetwork}
          className="border-1 border-[#B5B5B5] rounded-circle"
          target="_blank"
          icon={cloneElement(socialNetworkIcons[socialNetwork], {
            className: 'w-4 h-4',
          })}
        ></IconButton>
      ))}
    </div>
  )
}
