import {
  Children,
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'

import type { PaginationContextState } from './types'
import React from 'react'

const PaginationContext = createContext<PaginationContextState>(
  {} as PaginationContextState,
)

export const Pagination: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [steps, setSteps] = useState<React.ReactNode[]>([])

  const totalSteps = useMemo(() => steps.length, [steps])

  const resetSteps = useCallback(() => setActiveStep(0), [])

  const next = useCallback(() => {
    const nextStep = Math.min(activeStep + 1, totalSteps)

    if (nextStep < totalSteps) {
      setActiveStep(nextStep)
    }
  }, [activeStep, totalSteps])

  const back = useCallback(
    () => setActiveStep((prev) => Math.max(prev - 1, 0)),
    [],
  )

  const context = useMemo<PaginationContextState>(
    () => ({
      activeStep,
      steps,
      setSteps,
      totalSteps,
      resetSteps,
      next,
      back,
    }),
    [activeStep, steps, totalSteps, next, back],
  )

  return (
    <PaginationContext.Provider value={context}>
      {children}
    </PaginationContext.Provider>
  )
}

export const useDrawerPaginationContext = () =>
  useContext(PaginationContext) as PaginationContextState
