import * as Sentry from '@sentry/react'

import { ProductCard } from '@lounge-fe/ui-kit'
import { Price } from './Price'
import { StandardProductCardQuickAddProps } from './types'
import { useAddCartItems } from '@/features/cart/hooks'
import { base64 } from '@/utils/base64'
import { AddCartItemPayload } from '@/features/cart/types'
import { useDataTranslation } from '@/features/localisation/hooks/useDataTranslation/useDataTranslation'

const getPayload = (
  id: number,
  color: string | undefined,
  setID: string | undefined,
) => {
  const payload: AddCartItemPayload = {
    id,
    quantity: 1,
    properties: { color },
  }

  if (setID) {
    payload.properties!._setID = setID
  }

  return payload
}

export const QuickAdd = Sentry.withErrorBoundary(
  ({
    handle,
    image,
    title,
    price,
    compareAtPrice,
    color,
    options,
    inStock,
    variants,
    linkedBottoms,
    onQuickAdd,
    isLoading,
  }: StandardProductCardQuickAddProps) => {
    const addItems = useAddCartItems()
    const { t } = useDataTranslation()

    if (!inStock) {
      return null
    }

    const handleSubmit = async (
      variantId: number,
      linkedBottomsVariantId?: number,
    ) => {
      let payload = [getPayload(variantId, color, undefined)]
      if (linkedBottomsVariantId) {
        const setID = base64.encode([variantId, linkedBottomsVariantId])
        payload = [
          getPayload(variantId, color, setID),
          getPayload(linkedBottomsVariantId, color, setID),
        ]
      }
      await addItems.mutateAsync(payload)
      onQuickAdd?.()
    }

    return (
      <ProductCard.QuickAdd
        productTitle={title}
        productImage={image}
        price={<Price price={price} />}
        compareAtPrice={
          compareAtPrice ? <Price price={compareAtPrice} /> : undefined
        }
        color={color}
        options={options.map((option) => ({ ...option, name: t(option.name) }))}
        linkedBottoms={linkedBottoms}
        variants={variants}
        onSubmit={handleSubmit}
        onUnhandledException={Sentry.captureException}
        isLoading={isLoading}
      />
    )
  },
  {},
)
