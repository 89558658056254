import React from 'react'

import { SvgProps } from '../../types'
import { Icon } from '../../Icon'

export const DE: React.FC<SvgProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path fill="#ffda44" d="m0 345 256.7-25.5L512 345v167H0z" />
      <path fill="#d80027" d="m0 167 255-23 257 23v178H0z" />
      <path fill="#333" d="M0 0h512v167H0z" />
    </Icon>
  )
}
