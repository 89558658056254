import React from 'react'

import { SvgProps } from '../../types'
import { Icon } from '../../Icon'

export const NL: React.FC<SvgProps> = (props) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path fill="#eee" d="m0 167 253.8-19.3L512 167v178l-254.9 32.3L0 345z" />
      <path fill="#a2001d" d="M0 0h512v167H0z" />
      <path fill="#0052b4" d="M0 345h512v167H0z" />
    </Icon>
  )
}
