import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@lounge-fe/auth'
import { Button, Icon, Link, Text } from '@lounge-fe/ui-kit'
import { useShopify } from '../../../../hooks/useShopify'

export const AccountMenuContent = () => {
  const { t } = useTranslation()
  const { status, logout } = useAuth()
  const { routes } = useShopify()

  const {
    mutate: handleLogout,
    isPending: isLogoutLoading,
    isSuccess: isLogoutSuccess,
  } = useMutation({ mutationFn: logout })

  const authenticatedLinks = [
    {
      text: t('account.details.title'),
      href: `${routes.account_url}#/`,
      Icon: Icon.Account,
    },
    {
      text: t('account.orders.title'),
      href: `${routes.account_url}#/orders`,
      Icon: Icon.ParcelReturn,
    },
    {
      text: t('account.wishlist.title'),
      href: `${routes.account_url}#/wishlist`,
      Icon: Icon.Heart,
    },
    {
      text: t('account.preferences.title'),
      href: `${routes.account_url}#/preferences`,
      Icon: Icon.Cog,
    },
  ]

  // We need to add isLogoutSuccess to the condition because the redirect
  // is not instantaneous but the promise might be resolved.
  const isLoggingOut = isLogoutLoading || isLogoutSuccess

  if (status === 'authenticated' || isLoggingOut) {
    return (
      <>
        <div className="flex flex-col gap-6 my-5">
          {authenticatedLinks.map(({ text, href, Icon }, index) => (
            <Link
              key={index}
              href={href}
              className="no-underline font-regular flex gap-x-3 items-center hover:underline"
            >
              <span>
                <Icon fill="none" className="w-4" />
              </span>
              <span>{text}</span>
            </Link>
          ))}
        </div>
        <div className="pt-4 border-t border-primary-default">
          <Button
            onClick={() => handleLogout()}
            isLoading={isLoggingOut}
            variant="outline"
            className="w-full"
          >
            {t('action.sign_out')}
          </Button>
        </div>
      </>
    )
  }

  return (
    <>
      <Text className="text-center mt-3">
        {t('account.menu.unauthenticated_message')}
      </Text>
      <div className="flex pt-4 gap-x-8">
        <Button
          as="a"
          href={routes.account_register_url}
          variant="outline"
          className="flex-1"
        >
          {t('action.sign_up')}
        </Button>
        <Button as="a" href={routes.account_login_url} className="flex-1">
          {t('action.sign_in')}
        </Button>
      </div>
    </>
  )
}
