import { useShopify } from '@/hooks/useShopify'
import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import { Popover } from '@lounge-fe/ui-kit'
import { createPortal } from 'react-dom'
import { ModalGroupModal } from './ModalGroupModal'

export const ModalGroup: ShopifySectionComponent<{}> = () => {
  const { modals } = useShopify()
  return (
    <Popover.Group>
      {modals.map((modal) => {
        const container = document.querySelector(
          `div[data-modal-id="${modal.sectionId}"]`,
        )

        if (!container) {
          return null
        }

        return createPortal(
          <ModalGroupModal key={modal.modalId} {...modal} />,
          container,
        )
      })}
    </Popover.Group>
  )
}
