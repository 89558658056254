import { useService } from '@/hooks/useService'
import { RestResponse } from '@lounge-fe/network'
import { ProductConnection } from '@shopify/hydrogen-react/storefront-api-types'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { WishlistService } from '../services'
import { AddWishlistRequest } from '../services/WishlistService/types'

export const useWishlistAdd = (
  options?: Omit<
    UseMutationOptions<
      RestResponse<ProductConnection>,
      unknown,
      AddWishlistRequest,
      unknown
    >,
    'mutationFn'
  >,
) => {
  const wishlistService = useService(new WishlistService())

  return useMutation({
    mutationFn: wishlistService.add,
    ...options,
  })
}
