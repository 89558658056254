import { Transition } from '@lounge-fe/ui-kit'
import classNames from 'classnames'
import { NavigationTile } from '../../Navigation/NavigationTile'
import React from 'react'
import { SubNavigationTileGridProps } from './types'

export const SubNavigationTileGrid: React.FC<SubNavigationTileGridProps> = ({
  tiles,
  showTiles,
}) => {
  return (
    <Transition.Fade
      show={showTiles}
      className={classNames('w-full sm:flex sm:gap-x-2 sm:justify-between')}
    >
      {tiles
        .filter(({ show_desktop }) => show_desktop)
        .map((tile) => (
          <NavigationTile
            key={tile.title}
            aspectRatio="1/1"
            href={tile.link}
            text={tile.title}
            subtitle={null}
            image={
              tile.image
                ? {
                    src: tile.image.src,
                    width: 350,
                    height: 350,
                  }
                : undefined
            }
            showImage={true}
            overlay={tile.overlay}
          />
        ))}
    </Transition.Fade>
  )
}
