import { forwardRef } from 'react'

import { useSelectContext } from './Select'
import { Option } from './Option'
import { SelectSelectProps } from './types'

export const Menu = forwardRef<HTMLSelectElement, SelectSelectProps>(
  ({ placeholder, children, ...props }, ref) => {
    const { disabled, id, helperTextId, error } = useSelectContext()
    return (
      <select
        disabled={disabled}
        id={id}
        aria-describedby={helperTextId}
        aria-invalid={error}
        ref={ref}
        {...props}
      >
        {placeholder && <Option hidden>{placeholder}</Option>}
        {children}
      </select>
    )
  },
)

Menu.displayName = 'Select.Menu'
