import classNames from 'classnames'
import { FC, useCallback } from 'react'

import { Icon } from '../../Icon'
import { IconButton } from '../../IconButton'
import { useCarouselContext } from '../Carousel'

import { CarouselArrowsProps } from './types'

export const Arrows: FC<CarouselArrowsProps> = ({
  className,
  children,
  ...props
}) => {
  const { embla, viewportId, canScrollNext, canScrollPrev } =
    useCarouselContext()

  const onNextClick = useCallback(() => embla?.scrollNext(), [embla])
  const onPreviousClick = useCallback(() => embla?.scrollPrev(), [embla])

  return (
    <fieldset
      className={classNames('flex gap-x-4', className)}
      aria-controls={viewportId}
      {...props}
    >
      <IconButton
        icon={
          <Icon.ChevronLeft
            className={classNames('w-2', !canScrollPrev && '!text-subtle')}
          />
        }
        aria-label="Previous slide"
        variant="outline"
        className="!border-[#7D7D7D] disabled:!border-[#C8C8C8]"
        onClick={onPreviousClick}
        disabled={!canScrollPrev}
        size="lg"
      />
      {children}
      <IconButton
        icon={
          <Icon.ChevronRight
            className={classNames('w-2', !canScrollNext && '!text-subtle')}
          />
        }
        aria-label="Next slide"
        onClick={onNextClick}
        variant="outline"
        className="!border-[#7D7D7D] disabled:!border-[#C8C8C8]"
        disabled={!canScrollNext}
        size="lg"
      />
    </fieldset>
  )
}
