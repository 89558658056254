import { useShopify } from '@/hooks/useShopify'
import { useMemo } from 'react'
import { DEFAULT_STORE, STORES } from '../stores'

export const useCurrentStore = () => {
  const shopify = useShopify()

  return useMemo(
    () =>
      STORES.filter(({ shortName }) => shortName !== 'ROW').find(({ shop }) =>
        shopify.shop.includes(shop),
      ) ?? DEFAULT_STORE,
    [window.location.hostname],
  )
}
