import classNames from 'classnames'
import { Children, FC, useCallback } from 'react'

import { useCarouselContext } from '../Carousel'

import { CarouselThumbnailsProps } from './types'

export const Thumbnails: FC<CarouselThumbnailsProps> = ({
  children,
  ...props
}) => {
  const { embla, selectedSlide, viewportId } = useCarouselContext()

  const onThumbnailClick = useCallback(
    (idx: number) => {
      embla?.scrollTo(idx)
    },
    [embla],
  )

  return (
    <fieldset aria-controls={viewportId} {...props}>
      {Children.map(children, (slide, idx) => (
        <button
          key={`thumbnail-${idx}`}
          className={classNames(
            idx === selectedSlide
              ? 'border-white'
              : 'border-transparent after:conntent-empty after:absolute after:inset-0 after:bg-white after:opacity-50',
            'border border-2 aspect-[5/7] relative rounded-sm overflow-hidden',
          )}
          onClick={() => onThumbnailClick(idx)}
          aria-current={selectedSlide === idx}
          aria-label={`Go to slide ${idx + 1}`}
        >
          {slide}
        </button>
      ))}
    </fieldset>
  )
}
