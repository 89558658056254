import { Service } from '@/lib'
import { RestResponse } from '@lounge-fe/network'
import { ProductConnection } from '@shopify/hydrogen-react/storefront-api-types'
import {
  AddWishlistRequest,
  DeleteWishlistRequest,
  GetWishlistRequest,
} from './types'

export class WishlistService extends Service {
  get = (
    request: GetWishlistRequest = {},
  ): Promise<RestResponse<ProductConnection>> =>
    this.client.get<ProductConnection>('/customer/wishlist', request)

  add = (
    request: AddWishlistRequest,
  ): Promise<RestResponse<ProductConnection>> =>
    this.client.post<ProductConnection>(`/customer/wishlist`, request)

  delete = (
    request: DeleteWishlistRequest,
  ): Promise<RestResponse<ProductConnection>> =>
    this.client.delete<ProductConnection>(
      `/customer/wishlist/${btoa(request.id)}`,
    )
}
