import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'

import { Card, Carousel, Text, Wrapper } from '@lounge-fe/ui-kit'
import useEmblaCarousel from 'embla-carousel-react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import { CardCarouselData } from './types'

export const CardCarousel: ShopifySectionComponent<CardCarouselData> = ({
  data = { slides: [], title: '' },
}) => {
  const { slides, title } = data
  const [emblaRef, embla] = useEmblaCarousel(
    {
      align: 'start',
      containScroll: 'trimSnaps',
      skipSnaps: true,
    },
    [WheelGesturesPlugin()],
  )

  return (
    <Wrapper className="py-4">
      <Carousel emblaRef={emblaRef} embla={embla}>
        {title && (
          <Text className="mb-8" variant="title-four">
            {title}
          </Text>
        )}
        <Carousel.Viewport showOverflow>
          <Carousel.Container className="auto-cols-[calc(75%-16px)] md:auto-cols-[calc(50%-16px)] xl:auto-cols-[calc(33.33%-12px)] gap-x-6">
            {slides.map(({ title, body, image }, idx) => (
              <Carousel.Slide key={idx} index={idx}>
                <Card className="h-full">
                  <Card.Image
                    src={image?.src ?? ''}
                    alt={image?.alt ?? title}
                  />
                  <Card.Content>
                    {title && <Text variant="title-four">{title}</Text>}
                    {body && (
                      <Text
                        variant="body-lg"
                        dangerouslySetInnerHTML={{ __html: body }}
                      ></Text>
                    )}
                  </Card.Content>
                </Card>
              </Carousel.Slide>
            ))}
          </Carousel.Container>
        </Carousel.Viewport>
        <Carousel.Progress className="mt-8 [&_.progress-indicator]:bg-[#C8C8C8] [&_.progress-indicator]:rounded-sm pb-2 border-b border-[#C8C8C8]" />
      </Carousel>
    </Wrapper>
  )
}
