import {
  FormEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  CloseIconButton,
  Icon,
  Input,
  usePopoverContext,
} from '@lounge-fe/ui-kit'
import { useSearchContext } from '../SearchProvider'
import classNames from 'classnames'

export const SearchForm = forwardRef<HTMLInputElement>((_, ref) => {
  const { t } = useTranslation()
  const { search, onClear, setSearch } = useSearchContext()
  const { onClose } = usePopoverContext()

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback((evt) => {
    evt.preventDefault()
  }, [])

  const handleChange: FormEventHandler<HTMLInputElement> = useCallback(
    (evt) => setSearch(evt.currentTarget.value),
    [],
  )

  return (
    <form className="flex items-center w-full gap-4" onSubmit={handleSubmit}>
      <Input
        className={classNames(
          'w-full',
          String.raw`[&_.input-group\_\_control]:rounded-circle`,
          '[&_input]:py-1 [&_input]:px-2 [&_input]:text-sm',
        )}
        placeholder={t('action.search_products')}
        aria-label={t('action.search')}
        id="search-products"
        name="search-products"
        value={search}
        onChange={handleChange}
        startAdornment={<Icon.Search width={16} height={16} />}
        ref={ref}
      />
      <CloseIconButton
        type="reset"
        aria-label={t('action.clear')}
        onClick={() => {
          onClose()
          onClear()
        }}
        size="md"
      />
    </form>
  )
})
