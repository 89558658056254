import React, { useId } from 'react'

import { Icon } from '../Icon'
import { SvgProps } from '../types'

export const Gift: React.FC<SvgProps> = (props) => {
  const id = useId()
  return (
    <Icon
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${id})`}>
        <path
          d="M5.12766 0.964986C4.18266 0.959986 3.26266 1.86999 3.68266 2.99999H2.09766C1.83244 2.99999 1.57809 3.10534 1.39055 3.29288C1.20301 3.48042 1.09766 3.73477 1.09766 3.99999V4.99999C1.09766 5.13259 1.15033 5.25977 1.2441 5.35354C1.33787 5.44731 1.46505 5.49999 1.59766 5.49999H6.09766V3.99999H7.09766V5.49999H11.5977C11.7303 5.49999 11.8574 5.44731 11.9512 5.35354C12.045 5.25977 12.0977 5.13259 12.0977 4.99999V3.99999C12.0977 3.73477 11.9923 3.48042 11.8048 3.29288C11.6172 3.10534 11.3629 2.99999 11.0977 2.99999H9.51266C10.0977 1.36499 7.89766 0.209986 6.88266 1.61999L6.59766 1.99999L6.31266 1.60999C5.99766 1.16499 5.56266 0.969986 5.12766 0.964986ZM5.09766 1.99999C5.54266 1.99999 5.76766 2.53999 5.45266 2.85499C5.13766 3.16999 4.59766 2.94499 4.59766 2.49999C4.59766 2.36738 4.65033 2.2402 4.7441 2.14643C4.83787 2.05266 4.96505 1.99999 5.09766 1.99999ZM8.09766 1.99999C8.54266 1.99999 8.76766 2.53999 8.45266 2.85499C8.13766 3.16999 7.59766 2.94499 7.59766 2.49999C7.59766 2.36738 7.65033 2.2402 7.7441 2.14643C7.83787 2.05266 7.96505 1.99999 8.09766 1.99999ZM1.59766 5.99999V9.99999C1.59766 10.2652 1.70301 10.5196 1.89055 10.7071C2.07809 10.8946 2.33244 11 2.59766 11H10.5977C10.8629 11 11.1172 10.8946 11.3048 10.7071C11.4923 10.5196 11.5977 10.2652 11.5977 9.99999V5.99999H7.09766V9.99999H6.09766V5.99999H1.59766Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect
            width="12"
            height="12"
            fill="currentColor"
            transform="translate(0.597656)"
          />
        </clipPath>
      </defs>
    </Icon>
  )
}
