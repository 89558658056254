import { Text } from '@lounge-fe/ui-kit'

import { SearchMenuItemProps } from './types'

export const MenuItem = ({ children, ...props }: SearchMenuItemProps) => (
  <Text
    as="button"
    variant="title-six"
    className="capitalize cursor-pointer inline"
    {...props}
  >
    {children}
  </Text>
)
