import cookies from 'js-cookie'
import { useMemo } from 'react'
import { STORES } from '../stores'

export const USER_STORE_COOKIE = 'user_store'

type UserLocationValue = (typeof STORES)[number]['shop']

export const useUserStoreCookie = (): [
  string | undefined,
  (location: UserLocationValue) => void,
] => {
  const setCookie = (location: UserLocationValue) => {
    cookies.set(USER_STORE_COOKIE, location, {
      expires: 7,
      domain: '.lounge.com',
    })
  }

  const cookie = useMemo(() => cookies.get(USER_STORE_COOKIE), [])

  return [cookie, setCookie]
}
