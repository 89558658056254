import { SearchProps } from './types'
import { twMerge } from 'tailwind-merge'

export const Overlay = ({ isFull, className, ...props }: SearchProps) => {
  const { children } = props

  return (
    <div className={twMerge('w-full h-full bg-white', className)} {...props}>
      {children}
    </div>
  )
}
