import { CloseIconButton } from '../CloseIconButton'
import { Popover } from '../Popover'
import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'
import { DrawerHeaderProps } from './types'

export const Header: React.FC<DrawerHeaderProps> = ({
  className,
  children,
  triggerClassName,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <header
      className={twMerge(
        'relative flex items-center justify-between px-4 py-6 border-b',
        className,
      )}
      {...props}
    >
      {children}
      <div className={triggerClassName}>
        <Popover.Trigger>
          <CloseIconButton
            className="!bg-transparent !h-8 !w-8"
            aria-label={t('action.close')}
          />
        </Popover.Trigger>
      </div>
    </header>
  )
}
