import { Service } from '@/lib'
import { GetJobResponse, GetJobsResponse, SubmitProspectRequest } from './types'

const fileToBase64Content = async (
  name: string,
  file: File,
  includeMimeType = false,
) => {
  const content = new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

  const encoded = await content
  const base64Content = encoded.split(',')[1]
  const res = {
    [`${name}_content`]: base64Content,
    [`${name}_content_filename`]: file.name,
  }

  if (includeMimeType) {
    res[`${name}_content_mime_type`] = file.type
  }

  return res
}

export class CareersService extends Service {
  getJobs() {
    return this.client.get<GetJobsResponse>('/careers/jobs')
  }

  getJob(id: number) {
    return this.client.get<GetJobResponse>(`/careers/jobs/${id}`)
  }

  async submitApplication(id: number, data: Record<string, unknown>) {
    let requestBody: Record<string, unknown> = {}

    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value) && value[0] instanceof File) {
        requestBody = {
          ...requestBody,
          ...(await fileToBase64Content(key, value[0])),
        }
      } else {
        requestBody[key] = value
      }
    }

    return this.client.post(`/careers/jobs/${id}/applications`, requestBody)
  }

  async submitProspect({ resume, ...request }: SubmitProspectRequest) {
    return this.client.post(`/careers/prospects`, {
      ...request,
      ...(await fileToBase64Content('resume', resume[0], true)),
    })
  }
}
