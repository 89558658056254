import classNames from 'classnames'
import { BoxProps } from '@lounge-fe/ui-kit'

export const AuthFormLayout = ({
  children,
  className,
}: BoxProps<'div'>): JSX.Element => {
  return (
    <div
      className={classNames(
        'grid grid-flow-col auto-cols-fr overflow-hidden min-h-full',
        className,
      )}
    >
      {children}
    </div>
  )
}
