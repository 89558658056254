import { QueryClient, QueryKey } from '@tanstack/react-query'

import { getEmptyCart } from './utils'

import type {
  Cart,
  CartAdapter,
  CartManagerOptions,
  AddCartItemPayload,
  ICartPlugin,
  UpdateCartPayload,
  ICartPluginHandlers,
} from './types'

export const CART_QUERY_KEY: QueryKey = ['cart']

export class CartManager {
  private adapter: CartAdapter
  private queryClient: QueryClient
  private plugins: Map<string, ICartPlugin>

  constructor({
    queryClient,
    adapter,
    initialCart = getEmptyCart(),
    plugins,
  }: CartManagerOptions) {
    this.queryClient = queryClient
    this.adapter = adapter
    this.queryClient.setQueryData(CART_QUERY_KEY, initialCart)
    this.plugins = this.buildPlugins(plugins)
  }

  get = async (): Promise<Cart> => this.formatCart(await this.adapter.get())

  addItems = (items: AddCartItemPayload[]) =>
    this.withCache(async () => {
      const cart = await this.adapter.addItems(items)
      return this.applyPluginHandler(cart, 'onAddItems')
    })

  addItem = (item: AddCartItemPayload): Promise<Cart> => this.addItems([item])

  clear = (): Promise<Cart> =>
    this.withCache(async () => {
      const cart = await this.adapter.clear()
      return this.applyPluginHandler(cart, 'onClear')
    })

  update = (updatePayload: UpdateCartPayload): Promise<Cart> =>
    this.withCache(async () => {
      const cart = await this.adapter.update(updatePayload)
      return this.applyPluginHandler(cart, 'onUpdate')
    })

  removeItems = (ids: (number | string)[]): Promise<Cart> =>
    this.withCache(async () => {
      const cart = await this.adapter.removeItems(ids)
      return this.applyPluginHandler(cart, 'onRemoveItems')
    })

  removeItem = async (id: number | string): Promise<Cart> =>
    this.removeItems([id])

  plugin = <T extends ICartPlugin>(
    plugin: new (...args: any[]) => T,
  ): T | undefined => this.plugins.get(plugin.name) as T | undefined

  hasPlugin = <T extends ICartPlugin>(
    plugin: new (...args: any[]) => T,
  ): boolean => this.plugins.has(plugin.name)

  private withCache = async (fn: () => Promise<Cart>) => {
    await this.queryClient.cancelQueries({ queryKey: CART_QUERY_KEY })
    const newCart = await this.formatCart(await fn())
    this.queryClient.setQueryData(CART_QUERY_KEY, newCart)
    return newCart
  }

  private formatCart = (newCart: Cart): Promise<Cart> => {
    const mergedCart = this.mergeCart(
      this.queryClient.getQueryData(CART_QUERY_KEY) as Cart,
      newCart,
    )
    return this.applyPluginHandler(mergedCart, 'onFormatCart')
  }

  private buildPlugins = (plugins: ICartPlugin[]): Map<string, ICartPlugin> => {
    const pluginsMap = new Map<string, ICartPlugin>()

    plugins.forEach((plugin) => {
      pluginsMap.set(plugin.constructor.name, plugin.init(this))
    })

    return pluginsMap
  }

  private applyPluginHandler = async (
    cart: Cart,
    handler: keyof ICartPluginHandlers,
  ): Promise<Cart> => {
    let result = cart
    const plugins = Array.from(this.plugins.values())
    for (const plugin of plugins) {
      if (plugin.enabled) {
        result = (await plugin[handler]?.(result)) || result
      }
    }

    return result
  }

  private mergeCart = (cart: Cart, newCart: Cart): Cart => ({
    ...cart,
    ...newCart,
    meta: {
      ...(cart?.meta ?? {}),
      ...newCart.meta,
    },
  })
}
