import { useEffect, useRef, useState } from 'react'
import { AnimatePresence, useInView } from 'framer-motion'
import classNames from 'classnames'
import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import { useMediaQuery } from '@lounge-fe/ui-kit'
import { Video } from './types'
import { motion } from 'framer-motion'
import { useVideoProgress } from './hooks/useVideoProgress'
import { useCalculateScaleFactor } from './hooks/useScale'
import { VideoData } from './types'

export const VideoSection: ShopifySectionComponent<VideoData> = ({ data }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const containerRef = useRef(null)
  const isDesktop = useMediaQuery(({ screens }) => `(min-width: ${screens.sm})`)
  const [src, setSrc] = useState<Video>()
  const inView = useInView(containerRef, { amount: 0.9 })

  useEffect(() => {
    setSrc(isDesktop ? video : video_mobile)
    videoRef.current?.load()
  }, [src, isDesktop])

  //DATA
  const { video, video_mobile, video_animation } = data || {}

  // SCALE ANIMATION
  const [useWrapper, setUseWrapper] = useState(true)
  const height = window.innerHeight
  const width = window.innerWidth
  const elementHeight = videoRef.current?.clientHeight ?? height
  const elementWidth = videoRef.current?.clientWidth ?? width

  const scale = useCalculateScaleFactor(elementHeight, elementWidth)
  let scaleFactor = scale.scaleFactor

  //MIDDLE OF ELEMENT
  const elementMiddle = (elementHeight * scaleFactor - height) / 2

  //MIDDLE OF VIDEO REF
  const videoProgress = useVideoProgress(videoRef, inView)

  //GET videoProgress from useVideoProgress
  const { play, pause } = videoProgress

  useEffect(() => {
    if (inView) {
      play()
    } else {
      pause()
    }
  }, [inView])

  //NEED TO LOOK INTO THIS MORE
  if (video_animation == false) {
    scaleFactor = 1
  }

  return (
    <AnimatePresence>
      <motion.div
        variants={{
          default: {
            backgroundColor: '#fff',
            zIndex: 0,
            transition: {
              duration: 0.5,
              ease: 'easeOut',
            },
          },
          large: {
            backgroundColor: '#fff',
            zIndex: 50,
            transition: {
              duration: 0.5,
              ease: 'easeOut',
            },
          },
        }}
        animate={inView ? 'large' : 'default'}
        className={`relative ${!useWrapper ? 'z-50' : ''}`}
      >
        <div
          className="py-12"
          style={{ height: elementHeight * 2, position: 'relative' }}
        >
          <div
            ref={containerRef}
            data-container
            className={classNames(
              'sticky top-0 h-screen flex wrapper',

              !inView ? 'z-50' : '',
              video_animation
                ? ''
                : 'pt-[64px] pb-[64px] md:pt-[104px] md:pb-[104px] !z-0 w-full',
            )}
          >
            {video?.sources && (
              <motion.div
                className="flex flex-1 relative"
                variants={{
                  default: {
                    scale: 1,
                    y: 0,
                    transition: {
                      duration: 0.5,
                      ease: 'easeOut',
                    },
                  },
                  large: {
                    scale: scaleFactor,
                    y:
                      (scaleFactor * elementHeight - elementHeight) / 2 -
                      elementMiddle,
                    transition: {
                      duration: 0.5,
                      ease: 'easeOut',
                    },
                  },
                }}
                animate={inView ? 'large' : 'default'}
              >
                <video
                  id="video"
                  ref={videoRef}
                  className="w-full h-full object-cover"
                  muted
                  title={video.alt!}
                  poster={video.preview_image?.src}
                >
                  {src?.sources.map((source, idx) =>
                    source.width > 1800 ? (
                      <source
                        key={idx}
                        src={source.url}
                        width={source.width}
                        height={source.height}
                        type={source.mimeType}
                      ></source>
                    ) : null,
                  )}
                </video>
                <video-play-btn
                  class={classNames(
                    'fixed h-10 w-10 bottom-6 right-6 transition-all duration-200 z-10',
                    inView ? 'opacity-100' : 'opacity-0',
                  )}
                  style={{
                    transform: `scale(${1 / scaleFactor}) translate(0%, -100%)`,
                  }}
                />
              </motion.div>
            )}
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default VideoSection
