import { useEffect, useState } from 'react'
import { useDrawerNavigationContext } from '../DrawerNavigation'
import { SubNavigationColumnProps } from './types'
import { Transition, useMediaQuery } from '@lounge-fe/ui-kit'
import classNames from 'classnames'
import { SubNavigationLinks } from './SubNavigationLinks'
import { SubNavigationLink } from './SubNavigationLink'
import { SubNavigationTiles } from './SubNavigationTiles'

export const SubNavigationColumn: React.FC<SubNavigationColumnProps> = ({
  columnTitle,
  subLinks,
  tiles,
  subMenuIndex,
}) => {
  const { size, activeItem } = useDrawerNavigationContext()
  const [showContent, setShowContent] = useState(false)
  const [columnClass, setColumnClass] = useState<string | undefined>()
  const isMobile = useMediaQuery(({ screens }) => `(max-width: ${screens.sm})`)

  useEffect(() => {
    switch (size) {
      case 'xl':
        setColumnClass(
          'sm:grid-cols-[repeat(2,fit-content(144px))] md:grid-cols-[repeat(3,fit-content(144px))]',
        )
        break
      case 'lg':
        setColumnClass('sm:grid-cols-[repeat(2,fit-content(144px))]')
        break
      default:
        setColumnClass('grid-cols-[fit-content(144px)]')
    }
  }, [size])

  useEffect(() => {
    if (columnTitle === activeItem) {
      const linksTimer = setTimeout(() => {
        setShowContent(true)
      }, 250)

      return () => {
        clearTimeout(linksTimer)
      }
    } else {
      setShowContent(false)
    }
  }, [columnTitle, activeItem])

  const hiddenClass =
    columnTitle !== activeItem || !showContent ? 'hidden' : undefined

  const flexClass = isMobile ? 'flex flex-col' : 'grid'

  return (
    <div
      className={classNames(
        'grid sm:gap-y-8 gap-x-16 grid-cols-1 md:grid-cols-[max-content,auto] auto-rows-max',
        hiddenClass,
      )}
    >
      <div
        className={classNames(
          'gap-y-8 gap-x-[68px] grid-flow-col',
          columnClass,
          flexClass,
        )}
      >
        {subLinks.map(
          ({ title: subLinkTitle, sub_links: columnLinks }, subLinksIndex) => {
            const showTitle = subLinks.length > 1

            return (
              <Transition.Fade
                key={subLinkTitle}
                show={showContent}
                enter="animate-fade-in"
                keepMounted
              >
                <SubNavigationLinks
                  title={showTitle ? subLinkTitle : undefined}
                >
                  {columnLinks.map(({ title, url }, columnLinksIndex) => (
                    <li key={`${columnTitle}-${subLinkTitle}-${title}`}>
                      <SubNavigationLink
                        variant={
                          columnLinksIndex !== columnLinks.length - 1
                            ? 'link'
                            : 'footer-link'
                        }
                        url={url}
                      >
                        {title}
                      </SubNavigationLink>
                    </li>
                  ))}
                </SubNavigationLinks>
                {subLinksIndex !== subLinks.length - 1 && (
                  <hr className="sm:hidden mt-6" />
                )}
              </Transition.Fade>
            )
          },
        )}
      </div>
      {tiles ? (
        <SubNavigationTiles
          showTiles={showContent}
          tiles={tiles?.filter(
            (tile) => tile.sub_menu_index - 1 === subMenuIndex,
          )}
        />
      ) : undefined}
    </div>
  )
}
