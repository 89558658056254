import classNames from 'classnames'
import { useMemo, useId, useEffect, useLayoutEffect, useRef } from 'react'

import { Collapsible } from '../Collapsible'

import { useAccordionContext } from './Accordion'
import { AccordionItemProps } from './types'

export const Item = ({
  title,
  children,
  id: providedId,
  className,
  initialOpen = false,
  onClick,
  ...props
}: AccordionItemProps) => {
  const generatedId = useId()
  const {
    openItems,
    handleClick,
    iconPosition,
    openIcon,
    closedIcon,
    disableToggle,
    divider,
    keepMounted = false,
  } = useAccordionContext()

  const id = providedId ?? generatedId

  const hasMounted = useRef(false)

  const open = useMemo(() => {
    return Boolean(openItems.find((item) => item === id))
  }, [openItems])

  useEffect(() => {
    hasMounted.current = true
    if (initialOpen) {
      handleClick(id, true)
    }
  }, [])

  const getIcon = (open: boolean, disableToggle: boolean | undefined) => {
    if (open) {
      return disableToggle ? null : openIcon
    }
    return closedIcon
  }

  const icon = useMemo(
    () => (
      <div
        className="[&_svg]:max-w-full [&_svg]:h-full [&_svg]:transition-transform"
        aria-hidden="true"
      >
        {getIcon(open, disableToggle)}
      </div>
    ),
    [open, disableToggle],
  )

  const buttonId = `accordion-button:${id}`
  const panelId = `accordion-panel:${id}`

  return (
    <div
      className={classNames(
        'flex flex-col',
        divider && 'border-b border-[#E7E7E7] last:border-b-0',
      )}
    >
      <button
        type="button"
        className={classNames(
          'flex',
          'items-center',
          iconPosition === 'end' ? 'justify-between' : 'justify-start',
          'm-0',
          'gap-3',
          'px-0',
          'py-3',
          'border-0',
          'bg-transparent',
          open && disableToggle && 'cursor-default',
          className,
        )}
        id={buttonId}
        aria-controls={panelId}
        aria-expanded={open}
        onClick={onClick ?? (() => handleClick(id, !open))}
      >
        {iconPosition === 'start' && icon}
        {title}
        {iconPosition === 'end' && icon}
      </button>

      <Collapsible
        open={open || (initialOpen && !hasMounted.current)}
        id={panelId}
        aria-labelledby={buttonId}
        transition={{ duration: '0.25s' }}
        keepMounted={keepMounted}
        {...props}
      >
        {children}
      </Collapsible>
    </div>
  )
}
