import classNames from 'classnames'
import { useMemo } from 'react'

import { Text } from '../../components'
import { Skeleton } from '../../components/Skeleton'
import { normaliseProductColor } from '../../utilities/normaliseProductColor'

import { SwatchesProps } from './types'
import { useProductCardContext } from './ProductCard'
import type { Swatch as SwatchType } from '../../components/Swatch/types'
import { Swatch } from '../../components/Swatch'

export const Swatches = ({
  swatches = [],
  isLoading = false,
  selectedSwatchId,
  onSwatchSelect,
  availableColors = {},
  canonicalColors = {},
  limit = 4,
}: SwatchesProps) => {
  const { href } = useProductCardContext()

  const validSwatches = useMemo(() => {
    const getHexes = (swatch: SwatchType) => {
      let hexes: string[] = []

      if (swatch.color && availableColors) {
        const normalised = normaliseProductColor(swatch.color)
        hexes = availableColors[normalised]
      }

      if (swatch.canonical_color && !hexes?.length) {
        const normalised = normaliseProductColor(swatch.canonical_color)
        hexes = [canonicalColors[normalised]]
      }

      // Fallback if no color or canonical_color is available
      if (!hexes?.length) {
        hexes = []
      }

      return {
        ...swatch,
        hexes,
      }
    }

    return swatches.map(getHexes).filter((swatch) => swatch.hexes?.length)
  }, [swatches])

  const slicedSwatches = validSwatches.slice(0, limit)
  const remaining = validSwatches.length - limit

  if (isLoading) {
    return (
      <Skeleton className="flex gap-x-2 items-center mt-2">
        <Skeleton.Block className="w-4 h-4" />
        <Skeleton.Block className="w-4 h-4" />
        <Skeleton.Block className="w-4 h-4" />
      </Skeleton>
    )
  }

  if (slicedSwatches.length === 0) return null

  return (
    <div className="flex gap-x-2 items-center mt-2">
      {slicedSwatches.map((swatch) => {
        return (
          <Swatch
            size="sm"
            key={swatch.id}
            swatch={swatch}
            hexes={swatch.hexes}
            isSelected={swatch.id === selectedSwatchId}
            onClick={() => onSwatchSelect(swatch.id)}
          />
        )
      })}
      {remaining > 0 && (
        <Text
          as="a"
          href={href}
          variant="body-sm"
          className="!leading-[1] hover:underline mx-1"
        >
          +{remaining}
        </Text>
      )}
    </div>
  )
}
