import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { clamp } from '../../utilities/clamp'

import { WizardContextState, WizardProps, Step } from './types'

const WizardContext = createContext<WizardContextState>(
  {} as WizardContextState,
)

export const Wizard = <TAggregateValues extends object>({
  children,
  initialValues,
  onSubmit,
}: WizardProps<TAggregateValues>): JSX.Element => {
  const [activeStep, setActiveStep] = useState(0)
  const [steps, setSteps] = useState<Step[]>([])
  const [values, setValues] = useState(initialValues)
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false)

  const totalSteps = useMemo(() => steps.length, [steps])

  const next = useCallback(
    async (stepValues: Partial<TAggregateValues>) => {
      const nextValues = { ...values, ...stepValues }
      const nextStep = Math.min(activeStep + 1, totalSteps)

      if (nextStep < totalSteps) {
        setValues(nextValues)
        setActiveStep(nextStep)
      } else {
        await onSubmit(nextValues, context)
        setIsSubmitSuccessful(true)
      }
    },
    [activeStep, totalSteps, values],
  )

  const back = useCallback(
    () => setActiveStep((prev) => Math.max(prev - 1, 0)),
    [],
  )

  const goto = useCallback(
    (step: number) => setActiveStep(clamp(step, 0, totalSteps - 1)),
    [totalSteps],
  )

  const context = useMemo<WizardContextState<TAggregateValues>>(
    () => ({
      activeStep,
      steps,
      setSteps,
      totalSteps,
      next,
      back,
      goto,
      values,
      isSubmitSuccessful,
    }),
    [activeStep, steps, totalSteps, next, back, goto, isSubmitSuccessful],
  )

  return (
    <WizardContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </WizardContext.Provider>
  )
}

export const useWizardContext = <T extends object = any>() =>
  useContext(WizardContext) as WizardContextState<T>
