import { HTMLAttributes, useMemo } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Divider, Icon, Text } from '@lounge-fe/ui-kit'
import { SetsPlugin } from '../../../../features/cart/plugins'
import { BasketLine } from './BasketLine'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useCart } from '@/features/cart/hooks/useCart'
import { useCartPlugin } from '@/features/cart/hooks'

export const BasketLines = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation()
  const plugin = useCartPlugin()
  const cart = useCart()

  const { itemCount = 0, items = [] } = cart?.data || {}
  const lines = useMemo(
    () =>
      plugin(SetsPlugin)?.groupCartBySets(items) ||
      // If sets plugin is not available, just return the items as they are
      items.map((item) => {
        return {
          id: item.id.toString(),
          items: [item],
          isSet: false,
          finalLinePrice: item.finalLinePrice,
          originalLinePrice: item.originalLinePrice,
          setCount: item.quantity,
        }
      }),
    [items],
  )

  return (
    <AnimatePresence mode="wait">
      {itemCount === 0 ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="flex flex-col items-center justify-center flex-1 w-full h-full gap-y-6"
          key="empty-bag"
        >
          <Text variant="body-lg">{t('cart.your_bag_is_empty')}</Text>
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className={classNames('flex-1', className)}
          key="items"
        >
          <AnimatePresence>
            {lines.map((line, i) => (
              <motion.div
                key={line.id}
                className="mb-3 xl:mb-6"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 1 }}
                layout="position"
              >
                <BasketLine
                  isSet={line.isSet}
                  id={line.id}
                  items={line.items}
                  finalLinePrice={line.finalLinePrice}
                  originalLinePrice={line.originalLinePrice}
                  setCount={line.setCount}
                />
                {i !== Object.keys(lines).length - 1 && (
                  <Divider className="mt-3 2xl:mt-6" />
                )}
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
