import { Children, useLayoutEffect } from 'react'
import { useDrawerPaginationContext } from './Pagination'
import React from 'react'
import { Transition } from '../Transition'
import { twMerge } from 'tailwind-merge'

export const PaginationSteps: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { setSteps, activeStep } = useDrawerPaginationContext()

  useLayoutEffect(
    () =>
      setSteps(
        Children.toArray(children)
          .filter((child): child is React.ReactElement =>
            React.isValidElement(child),
          )
          .map((child) => child),
      ),
    [],
  )

  return Children.map(children, (child, index) => (
    <Transition.Fade
      className={twMerge(
        'w-full flex flex-col overflow-y-auto',
        activeStep !== index ? 'absolute' : undefined,
      )}
      enter="animate-fade-in-slow"
      exit={
        activeStep !== index ? 'animate-fade-out-fast' : 'animate-fade-out-slow'
      }
      keepMounted
      show={activeStep === index}
    >
      {child}
    </Transition.Fade>
  ))
}
