import classNames from 'classnames'
import { FC } from 'react'

import { ModalBodyAspectRatio, ModalBodyProps } from './types'

const aspectRatioClassnames: { [key in ModalBodyAspectRatio]: string } = {
  auto: '',
  '2/1': 'sm:aspect-[2/1]',
}

export const Body: FC<ModalBodyProps> = ({
  className,
  aspectRatio = 'auto',
  ...props
}) => {
  return (
    <div
      className={classNames(
        'py-4 px-6 overflow-y-auto',
        aspectRatioClassnames[aspectRatio],
        className,
      )}
      {...props}
    />
  )
}
