import React, { useCallback, useEffect, useRef, useState } from 'react'

import { CollapsibleProps, AnimationState } from './types'

export const Collapsible = ({
  id,
  open,
  transition,
  preventMeasuringOnChildrenUpdate,
  children,
  keepMounted = false,
  ...props
}: CollapsibleProps): JSX.Element => {
  const [height, setHeight] = useState(0)
  const [isOpen, setIsOpen] = useState(open)
  const [animationState, setAnimationState] = useState<AnimationState>('idle')
  const collapsibleContainer = useRef<HTMLDivElement>(null)

  const isFullyOpen = animationState === 'idle' && open && isOpen
  const isFullyClosed = animationState === 'idle' && !open && !isOpen

  const unmountedContent = keepMounted ? (
    <div className="hidden">{children}</div>
  ) : null

  const content = !isFullyClosed ? children : unmountedContent

  const collapsibleStyles = {
    ...(transition && {
      transitionDuration: `${transition.duration}`,
      transitionTimingFunction: `${transition.timingFunction}`,
    }),
    ...{
      maxHeight: isFullyOpen ? 'none' : `${height}px`,
      overflow: isFullyOpen ? 'visible' : 'hidden',
    },
  }

  const handleCompleteAnimation = useCallback(
    ({ target }: React.TransitionEvent<HTMLDivElement>) => {
      if (target === collapsibleContainer.current) {
        setAnimationState('idle')
        setIsOpen(open)
      }
    },
    [open],
  )

  useEffect(() => {
    if (isFullyClosed || preventMeasuringOnChildrenUpdate) return
    setAnimationState('measuring')
  }, [children, isFullyClosed, preventMeasuringOnChildrenUpdate])

  useEffect(() => {
    if (open !== isOpen) {
      setAnimationState('measuring')
    }
  }, [open, isOpen])

  useEffect(() => {
    if (!open || !collapsibleContainer.current) return
    // If collapsible defaults to open, set an initial height
    setHeight(collapsibleContainer.current.scrollHeight)
  }, [])

  useEffect(() => {
    if (!collapsibleContainer.current) return

    switch (animationState) {
      case 'idle':
        break
      case 'measuring':
        setHeight(collapsibleContainer.current.scrollHeight)
        setAnimationState('animating')
        break
      case 'animating':
        setHeight(open ? collapsibleContainer.current.scrollHeight : 0)
    }
  }, [animationState, open, isOpen])

  return (
    <div
      className="py-0 max-h-[auto] overflow-hidden will-change-[max-height] transition-[max-height] duration-100 ease-out"
      id={id}
      style={collapsibleStyles}
      ref={collapsibleContainer}
      onTransitionEnd={handleCompleteAnimation}
      aria-hidden={!open}
      {...props}
    >
      {content}
    </div>
  )
}
