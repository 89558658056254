import { SelectStoreModal } from '@/features/localisation/components/SelectStoreModal'

export const CountrySelector = () => {
  return (
    <SelectStoreModal>
      <SelectStoreModal.Trigger
        renderStore={(store) => store.name}
        options={{ showLanguage: true }}
      />
      <SelectStoreModal.Content />
    </SelectStoreModal>
  )
}
