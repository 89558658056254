import classNames from 'classnames'
import { FC, HTMLAttributes } from 'react'

export const Header: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...props
}) => (
  <header
    className={classNames(
      'relative border-b border-primary-default pb-4 pl-6 sm:pl-12 pr-12 pt-6 sm:mx-6 sm:text-center',
      className,
    )}
    {...props}
  >
    {children}
  </header>
)
