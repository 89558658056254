import useEmblaCarousel from 'embla-carousel-react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'

import { CloseIconButton } from '../CloseIconButton'
import { Popover } from '../Popover'
import { Portal } from '../Portal'
import { Transition } from '../Transition'
import { useKeyPress } from '../../hooks'
import { Carousel } from '../Carousel'
import { Image } from '../Image'

import { LightboxProps, LightboxContextState } from './types'
import { ZoomableImage } from './ZoomableImage'

const LightboxContext = createContext<LightboxContextState>(
  {} as LightboxContextState,
)

export const Lightbox = <E extends HTMLElement>({
  media,
  children,
  ...props
}: LightboxProps<E>) => {
  const [emblaRef, embla] = useEmblaCarousel({
    axis: 'y',
    loop: true,
    draggable: false,
  })

  const isRightPress = useKeyPress('ArrowRight')
  const isLeftPressed = useKeyPress('ArrowLeft')
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(0)

  useEffect(() => {
    if (isLeftPressed) {
      embla?.scrollPrev()
    }
  }, [isLeftPressed, embla])

  useEffect(() => {
    if (isRightPress) {
      embla?.scrollNext()
    }
  }, [isRightPress, embla])

  const context = useMemo(
    () => ({
      embla,
      selectedSlideIndex,
      setSelectedSlideIndex,
    }),
    [embla, selectedSlideIndex],
  )

  return (
    <LightboxContext.Provider value={context}>
      <Popover {...props}>
        {({ onClose }) => (
          <>
            {children}
            <Portal>
              <Transition.Fade className="fixed inset-0 z-50">
                <Popover.Content className="w-full h-full max-h-full bg-[#070707]">
                  <div className="relative flex justify-center h-full">
                    <Carousel
                      emblaRef={emblaRef}
                      embla={embla}
                      className="relative flex justify-center w-auto h-full"
                      selectedSlideIndex={selectedSlideIndex}
                    >
                      <div className="hidden md:flex absolute z-10 bottom-6 max-w-screen-md">
                        <Carousel.Thumbnails className="flex items-center justify-center max-h-full overflow-auto gap-x-2 [&_button]:w-full">
                          {media.map(
                            (
                              {
                                zoomSrc,
                                data,
                                thumbnailImageData,
                                className,
                                ...item
                              },
                              idx,
                            ) => (
                              <Image
                                key={idx}
                                className={classNames(
                                  '!w-18 h-full',
                                  className,
                                )}
                                data={thumbnailImageData ?? data}
                                {...item}
                              />
                            ),
                          )}
                        </Carousel.Thumbnails>
                      </div>
                      <Carousel.Viewport className="bg-white">
                        <Carousel.Container className="!flex flex-col h-full !grid-flow-row">
                          {media.map((item, idx) => (
                            <Carousel.Slide
                              key={idx}
                              className="md:h-full min-h-screen md:max-h-full auto-cols-[100%]"
                              index={idx}
                            >
                              <ZoomableImage {...item} />
                            </Carousel.Slide>
                          ))}
                        </Carousel.Container>
                      </Carousel.Viewport>
                    </Carousel>
                  </div>
                  <CloseIconButton
                    onClick={onClose}
                    className="absolute right-10 top-10 !bg-[#F5F5F5]"
                    aria-label="Close"
                  />
                </Popover.Content>
              </Transition.Fade>
            </Portal>
          </>
        )}
      </Popover>
    </LightboxContext.Provider>
  )
}

export const useLightboxContext = () => useContext(LightboxContext)
