import React from 'react'
import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const GridSmall: React.FC<SvgProps> = ({ underline, ...props }) => {
  if (underline) {
    return (
      <Icon viewBox="0 0 16 20" {...props}>
        <rect x="0.4" y="0.4" width="15.2" height="15.2" strokeWidth="0.8" />
        <line x1="8.33333" y1="0" x2="8.33333" y2="16" strokeWidth="0.666667" />
        <line x1="0" y1="7.66667" x2="16" y2="7.66667" strokeWidth="0.666667" />
        <line y1="19.6" x2="16" y2="19.6" strokeWidth="0.8" />
      </Icon>
    )
  }

  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <rect x="0.4" y="0.4" width="15.2" height="15.2" strokeWidth="0.8" />
      <line x1="8.33333" y1="0" x2="8.33333" y2="16" strokeWidth="0.666667" />
      <line x1="0" y1="7.66667" x2="16" y2="7.66667" strokeWidth="0.666667" />
    </Icon>
  )
}
