import { PaymentProvider } from '@/types/PaymentProvider'
import { Icon } from '@lounge-fe/ui-kit'
import { cloneElement, ReactElement, useMemo } from 'react'
import { PaymentProvidersProps } from './types'

const paymentProviderIcons: Record<PaymentProvider, ReactElement> = {
  amex: <Icon.PaymentProviders.Amex aria-label="American Express" />,
  apple_pay: <Icon.PaymentProviders.ApplePay aria-label="Apple Pay" />,
  klarna: <Icon.PaymentProviders.Klarna aria-label="Klarna" />,
  clearpay: <Icon.PaymentProviders.ClearPay aria-label="Clearpay" />,
  afterpay: <Icon.PaymentProviders.AfterPay aria-label="Afterpay" />,
  paypal: <Icon.PaymentProviders.Paypal aria-label="PayPal" />,
  mastercard: <Icon.PaymentProviders.Mastercard aria-label="Mastercard" />,
  visa: <Icon.PaymentProviders.Visa aria-label="Visa" />,
}

export const PaymentProviders = ({
  paymentProviders,
}: PaymentProvidersProps) => {
  const enabledProviders = useMemo(
    () =>
      Object.entries(paymentProviders)
        .filter(([, enabled]) => enabled)
        .map(([provider]) => provider as PaymentProvider),
    [paymentProviders],
  )
  return (
    <div className="flex max-w-full gap-x-1 lg:gap-x-2 text-subtle">
      {enabledProviders.map((provider) =>
        cloneElement(paymentProviderIcons[provider], {
          key: provider,
          borderColor: '#7D7D7D',
          foregroundColor: '#C8C8C8',
          className: 'flex-[0_1_auto]',
        }),
      )}
    </div>
  )
}
