import { useMemo } from 'react'
import { TileData, UseTilesOptions } from './types'
import { NavigationTileProps } from '../Navigation/types'

const toNavigationTile = (tile: TileData): NavigationTileProps => ({
  href: tile.link,
  text: tile.title,
  subtitle: tile.subtitle,
  buttonText: tile.button,
  ...(tile.image?.src
    ? {
        image: {
          src: tile.image.src,
          width: 350,
          height: 350,
        },
      }
    : {}),
  showButton: Boolean(tile.button),
})

const groupTiles = (
  tiles: TileData[],
  menuItemsCount: number,
  maxTilesPerGroup: number,
  predicate: (tile: TileData) => boolean = () => true,
): NavigationTileProps[][] =>
  tiles.reduce((prev: NavigationTileProps[][], curr: TileData) => {
    if (curr.sub_menu_index < 1 || curr.sub_menu_index > menuItemsCount) {
      return prev
    }

    const index = curr.sub_menu_index - 1
    const currentTiles = prev[index] || []

    if (currentTiles.length < maxTilesPerGroup && predicate(curr)) {
      return [
        ...prev.slice(0, index),
        [...currentTiles, toNavigationTile(curr)],
        ...prev.slice(index + 1),
      ]
    }

    return prev
  }, [])

export const useTiles = ({
  tiles,
  menuItemsCount,
  mobileMenuItemsCount = menuItemsCount,
  maxTilesPerGroup = 2,
}: UseTilesOptions) => {
  const desktop = useMemo(
    () => groupTiles(tiles, menuItemsCount, maxTilesPerGroup),
    [tiles],
  )

  const mobile = useMemo(
    () =>
      groupTiles(
        tiles,
        mobileMenuItemsCount,
        maxTilesPerGroup,
        (tile) => tile.show_mobile,
      ),
    [tiles],
  )

  return { desktop, mobile }
}
