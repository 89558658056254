import { HorizontalGaugeBarProps, HorizontalGaugeLabelAlignment } from './types'
import { Text } from '../Text'
import { twMerge } from 'tailwind-merge'

const alignment: {
  [K in HorizontalGaugeLabelAlignment]: string
} = {
  left: 'text-left',
  right: 'text-right',
  center: 'text-center',
}

const HorizontalGaugeBar: React.FC<HorizontalGaugeBarProps> = ({
  className,
  label,
  labelAlignment = 'left',
  ...props
}) => {
  return (
    <div className={twMerge('w-full', alignment[labelAlignment])}>
      <div
        aria-label={label}
        className={twMerge(
          'transition-transform border-[0.6px] border-[#E7E7E7] mb-2 h-1 md:h-2 bg-black',
          className,
        )}
        {...props}
      />
      <Text as="span" variant="body-xs">
        {label}
      </Text>
    </div>
  )
}

export { HorizontalGaugeBar }
