import { Children, cloneElement, useCallback, useState } from 'react'
import { LayoutGroup } from 'framer-motion'

import {
  Box,
  Flex,
  Backdrop,
  Portal,
  usePopoverStore,
  preventBlurWithin,
  useLockedBody,
} from '@lounge-fe/ui-kit'

import { DesktopMainMenuProps } from './types'

export const MainMenu = ({
  children,
  onOpen,
  onClose,
}: DesktopMainMenuProps) => {
  const { setActiveId } = usePopoverStore()
  const [, setLockedBody] = useLockedBody()

  const [activeMainMenuIndex, setActiveMainMenuIndex] = useState<number | null>(
    null,
  )

  const onMainMenuClose = useCallback(() => {
    setActiveMainMenuIndex(null)
    setLockedBody(false)
    onClose?.()
  }, [])

  const onMainMenuOpen = useCallback((id: number | null) => {
    setActiveMainMenuIndex(id)
    setActiveId(null)
    setLockedBody(true)
    onOpen?.()
  }, [])

  return (
    <>
      <Flex as="nav" className="h-full desktop-navigation__nav">
        <Box
          as="ul"
          className="flex flex-1 p-0 m-0 z-40"
          role="menubar"
          onMouseLeave={onMainMenuClose}
          onBlur={preventBlurWithin(onMainMenuClose)}
        >
          <LayoutGroup id="megamenu">
            {Children.map(children, (child, index) =>
              cloneElement(child, {
                isOpen: activeMainMenuIndex === index,
                onOpen: () => {
                  onMainMenuOpen(index)
                },
                onClose: onMainMenuClose,
              }),
            )}
          </LayoutGroup>
        </Box>
      </Flex>
      <Portal>
        <Backdrop className="z-30" isOpen={activeMainMenuIndex !== null} />
      </Portal>
    </>
  )
}
