import { Link as BaseLink, IconLink } from '@lounge-fe/ui-kit'
import { LinkProps } from './types'
import classNames from 'classnames'
import { useDrawerPaginationContext } from '@lounge-fe/ui-kit/src/components/Drawer/Pagination'

export const Link: React.FC<React.PropsWithChildren<LinkProps>> = ({
  as,
  children,
  className,
  icon,
  iconPosition,
  onClick,
  paginate,
  variant,
}) => {
  const { next, back } = useDrawerPaginationContext()

  const handlePagination = () => {
    if (paginate === 'next') {
      next()
    } else if (paginate === 'back') {
      back()
    }
  }
  return (
    <IconLink
      as="li"
      icon={icon}
      iconColor="#888888"
      iconPosition={iconPosition}
      className={classNames('justify-between cursor-pointer', className)}
      onClick={() => {
        if (onClick) {
          onClick()
        }

        if (paginate) {
          handlePagination()
        }
      }}
    >
      <BaseLink variant={variant} as={as}>
        {children}
      </BaseLink>
    </IconLink>
  )
}
