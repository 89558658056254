import { useCallback, useMemo } from 'react'
import { useShopify } from '@/hooks/useShopify'

export const useFeatureFlags = () => {
  const { featureFlags: baseFeatureFlags } = useShopify()

  const featureFlags = useMemo(() => baseFeatureFlags || {}, [baseFeatureFlags])

  const queryFlags = useMemo(() => {
    if (typeof window === 'undefined') {
      return []
    }
    const flags = new URLSearchParams(window.location.search).get('ff')
    return flags ? flags.split(',') : []
  }, [])

  const getFlag = useCallback(
    (flag: string): boolean => {
      return featureFlags?.[flag] ?? queryFlags.includes(flag) ?? false
    },
    [featureFlags, queryFlags],
  )

  return { getFlag }
}
