import { ChangeEventHandler, Fragment, useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'

import { Text } from '@lounge-fe/ui-kit'

import { UnderwearCareBagPlugin } from '../../../cart/plugins'
import { useCartPlugin, useCart } from '../../..//cart/hooks'
import { Money } from '@shopify/hydrogen-react'
import { useShopify } from '@/hooks/useShopify'
import { useTranslation } from 'react-i18next'
import { BasketAddon } from './BasketAddon'

export const UnderwearCareBag = () => {
  const { t } = useTranslation()
  const plugin = useCartPlugin()
  const cart = useCart()
  const { currency } = useShopify()

  const price = plugin(UnderwearCareBagPlugin)?.getPrice() ?? 0

  const setUnderwearCareBag = useMutation({
    mutationFn: plugin(UnderwearCareBagPlugin)?.setUnderwearCareBag,
  })

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (evt) => setUnderwearCareBag.mutate(evt.currentTarget.checked),
    [],
  )

  return (
    <BasketAddon
      label={
        <>
          {`${t('cart.add_underwear_care_bag')} - `}
          <Text as="strong">
            <Money
              data={{
                amount: (price / 100).toString(),
                currencyCode: currency.active,
              }}
              as={Fragment}
            />
          </Text>
        </>
      }
      handleChange={handleChange}
      checked={Boolean(cart.data?.meta.underwearCareBag)}
      disabled={setUnderwearCareBag.isPending}
    />
  )
}
