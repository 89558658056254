import { createCompoundComponent } from '../../utilities'
import { Trigger } from '../Popover/Trigger'
import { Group } from '../Popover/Group'

import { ActionSheet as BaseActionSheet } from './ActionSheet'
import { Body } from './Body'
import { Content } from './Content'
import { Header } from './Header'
import { Footer } from './Footer'

export const ActionSheet = createCompoundComponent(BaseActionSheet, {
  Content,
  Header,
  Body,
  Trigger,
  Group,
  Footer,
})
