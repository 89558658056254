import { Link } from '@lounge-fe/ui-kit'
import { SubNavigationLinkProps } from './types'
import { usePostHog } from 'posthog-js/react'
import { NavigationEvent } from '@/feature-flags/enums/events.enum'

export const SubNavigationLink: React.FC<
  React.PropsWithChildren<SubNavigationLinkProps>
> = ({ children, url, variant }) => {
  const posthog = usePostHog()
  const textClass =
    variant === 'footer-link' ? 'underline text-[#888888]' : undefined

  return (
    <Link
      as="a"
      className={textClass}
      onClick={() => {
        posthog.capture(NavigationEvent.LinkVisit, {
          url,
        })
      }}
      variant={variant === 'link' ? 'title-six' : 'body-md'}
      href={url}
    >
      {children}
    </Link>
  )
}
