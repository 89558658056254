import { Children, cloneElement, isValidElement } from 'react'

import type { TableHeadProps } from './types'

export const Head = ({ children, ...props }: TableHeadProps) => (
  <thead {...props}>
    {Children.map(children, (child) =>
      isValidElement(child) ? cloneElement(child, { isHeader: true }) : child,
    )}
  </thead>
)
