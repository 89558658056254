import {
  Accordion,
  Divider,
  Link,
  Text,
  useMediaQuery,
} from '@lounge-fe/ui-kit'

import { MainMenuProps } from './types'
import { Fragment } from 'react'
import { useFeatureFlags } from '@/hooks/useFeatureFlags'

export const MainMenu = ({ menus }: MainMenuProps) => {
  const { getFlag } = useFeatureFlags()
  const isDesktop = useMediaQuery(({ screens }) => `(min-width: ${screens.lg})`)

  if (isDesktop) {
    return (
      <div className="flex gap-x-20">
        {menus.map((menu, idx) => (
          <div key={idx} className="flex flex-col items-start">
            <Text as="h3" variant="body-lg" className="font-regular mb-4">
              {menu.title}
            </Text>
            <nav>
              <ul className="flex flex-col">
                {menu.links.map((item, idx) => (
                  <li key={idx}>
                    <Link
                      href={item.url}
                      variant="body-sm"
                      className="!font-regular"
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        ))}
      </div>
    )
  }

  return (
    <Accordion keepMounted={getFlag('keep_accordion_content_mounted')}>
      {menus.map((menu, idx) => (
        <Fragment key={idx}>
          <Accordion.Item
            title={
              <Text as="h3" className="font-regular">
                {menu.title}
              </Text>
            }
          >
            <nav>
              <ul className="list-none pb-4">
                {menu.links.map((item, idx) => (
                  <li key={idx}>
                    <Link
                      href={item.url}
                      variant="body-sm"
                      className="!font-regular"
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </Accordion.Item>
        </Fragment>
      ))}
    </Accordion>
  )
}
