import { createContext, FC, forwardRef, useContext, useMemo } from 'react'

import { RadioGroupContextState, RadioGroupProps } from './types'

const RadioGroupContext = createContext<RadioGroupContextState>(
  {} as RadioGroupContextState,
)

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  ({ name, children, value, onChange, ...props }, ref) => {
    const context = useMemo(
      () => ({
        name,
        value,
        onChange,
      }),
      [name, value, onChange],
    )

    return (
      <RadioGroupContext.Provider value={context}>
        <div role="radiogroup" className="radio-group" ref={ref} {...props}>
          {children}
        </div>
      </RadioGroupContext.Provider>
    )
  },
)

RadioGroup.displayName = 'RadioGroup'

export const useRadioGroupContext = () => useContext(RadioGroupContext)
