import { twMerge } from 'tailwind-merge'

import { IconButton } from '../IconButton'
import { IconButtonProps } from '../IconButton/types'
import { Icon } from '../Icon'

export const CloseIconButton = ({
  className,
  ...props
}: Omit<IconButtonProps, 'icon'>) => (
  <IconButton
    className={twMerge('bg-[#F5F5F5] !w-6 !h-6', className)}
    icon={<Icon.Close />}
    {...props}
  />
)
