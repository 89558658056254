// Original src here: https://github.com/Shopify/hydrogen/blob/main/packages/hydrogen-react/src/image-size.ts

// based on the default width sizes used by the Shopify liquid HTML tag img_tag plus a 2560 width to account for 2k resolutions
// reference: https://shopify.dev/api/liquid/filters/html-filters#image_tag
export const IMG_SRC_SET_SIZES = [352, 832, 1200, 1920, 2560]

export interface Options {
  src: string
  crop?: 'top' | 'bottom' | 'left' | 'right' | 'center'
  scale?: 2 | 3
  width?: string | number
  height?: string | number
  quality?: number
  format?: string
  onError?: (error: unknown) => void
}

/**
 * Adds image size parameters to an image URL hosted by Shopify's CDN
 */
export function addImageSizeParametersToUrl({
  src,
  width,
  height,
  crop,
  scale,
  quality = 80,
  format = 'webp',
  onError,
}: Options): string {
  try {
    const newUrl = new URL(src.startsWith('//') ? `https:${src}` : src)

    const multipliedScale = scale ?? 1

    if (width) {
      let finalWidth: string

      if (typeof width === 'string') {
        finalWidth = (IMG_SRC_SET_SIZES[0] * multipliedScale)
          .toFixed(0)
          .toString()
      } else {
        finalWidth = (Number(width) * multipliedScale).toFixed(0).toString()
      }

      newUrl.searchParams.append('width', finalWidth)
    }

    if (quality && typeof quality === 'number') {
      newUrl.searchParams.append('quality', quality.toString())
    }

    if (height && typeof height === 'number') {
      newUrl.searchParams.append(
        'height',
        (height * multipliedScale).toFixed(0).toString(),
      )
    }

    crop && newUrl.searchParams.append('crop', crop)

    format && newUrl.searchParams.append('format', format)

    newUrl.searchParams.sort()
    // for now we intentionally leave off the scale param, and instead multiple width & height by scale instead
    // scale && newUrl.searchParams.append('scale', scale.toString());

    return newUrl.toString()
  } catch (error) {
    onError?.(error)
    return src
  }
}
