import classNames from 'classnames'

import { Box } from '../../components'

import { TileVideoProps } from './types'

export const Video = ({ className, video, alt }: TileVideoProps) => {
  if (!video?.sources || video.sources.length === 0) return null

  return (
    <Box className={classNames('tile__video', className)}>
      <video
        autoPlay
        muted
        loop
        playsInline
        className="w-full h-full object-cover"
        title={alt}
      >
        {video.sources
          .sort((a, b) => b.height - a.height)
          .map(({ url, mime_type }, idx) => (
            <source key={idx} src={url} type={mime_type} />
          ))}
      </video>
    </Box>
  )
}
