import React from 'react'
import {
  IconButton,
  Icon,
  Logo,
  Wrapper,
  Flex,
  Popover,
  Transition,
  Portal,
} from '@lounge-fe/ui-kit'

import { useShopify } from '../../../../../hooks/useShopify'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useScrollAnimation } from '../../../hooks/useScrollAnimation'
import { useNewNavigationContext } from '../Navigation'
import { useNewSearchContext } from '../SearchProvider'

import { useMediaQuery } from '@lounge-fe/ui-kit/src/hooks'

import { SearchForm } from './SearchForm'
import { WishlistButton } from './WishlistButton'
import { useRef } from 'react'
import { SearchFormMobile } from './SearchFormMobile'
import { HeaderProps } from './types'

export const HeaderComponent: React.FC<HeaderProps> = ({ onMenuClick }) => {
  const { t } = useTranslation()

  const { routes } = useShopify()

  const { hasScrollAnimation, searchComponent, basketComponent } =
    useNewNavigationContext()
  const isDesktop = useMediaQuery(({ screens }) => `(min-width: ${screens.md})`)

  const searchInputRef = useRef<HTMLInputElement | null>(null)

  const scrollAnimationProps = useScrollAnimation()
  const { hiddenState } = scrollAnimationProps

  const searchContext = useNewSearchContext()

  return (
    <Popover shards={[searchInputRef]} exclusive>
      {({ isOpen }) => (
        <>
          <div className={classNames('navigation-header__wrapper')}>
            <motion.header
              className={classNames(
                'bg-primary',
                'dark:bg-inverse',
                'relative',
                'w-full',
                'top-0',
                'left-0',
                'right-0',
                'h-[calc(var(--header-height)_-_1px)]',
                isDesktop &&
                  'after:content-empty after:absolute after:left-0 after:right-0 after:h-[1px] after:z-50 after:bg-neutral-100 after:pointer-events-none',
                hiddenState && 'after:hidden',
              )}
              {...{
                ...(hasScrollAnimation ? scrollAnimationProps : {}),
              }}
            >
              <Wrapper className="h-full w-screen max-w-full px-4 lg:px-10">
                <Transition.Fade
                  as={Flex}
                  className="items-center h-full"
                  show={!isOpen}
                  initial={false}
                  onAnimationEnd={() => {
                    if (isOpen) {
                      searchInputRef.current?.focus()
                    }
                  }}
                >
                  <Flex className={classNames('items-center flex-1')}>
                    <IconButton
                      onClick={onMenuClick}
                      type="button"
                      aria-label={t('action.open')}
                      size="md"
                      icon={<Icon.BurgerMenu />}
                    />
                  </Flex>
                  <Flex
                    as="a"
                    href={routes.root_url}
                    title="Lounge Underwear"
                    className={classNames('align-center justify-center flex-1')}
                  >
                    <Logo className="navigation__logo h-4 sm:h-5" />
                  </Flex>
                  <Flex
                    className={classNames('flex-1 justify-end gap-1 sm:gap-2')}
                  >
                    <Popover.Trigger>
                      <IconButton
                        icon={<Icon.Search width={16} height={16} />}
                        aria-label={t('action.open')}
                      />
                    </Popover.Trigger>

                    {isDesktop && (
                      <>
                        <WishlistButton />
                        <IconButton
                          type="button"
                          icon={<Icon.Account width={16} height={16} />}
                          href={routes.account_login_url}
                          aria-label={t('account.account')}
                        />
                      </>
                    )}
                    <div className="flex items-center justify-center w-8 h-8">
                      {basketComponent}
                    </div>
                  </Flex>
                </Transition.Fade>
              </Wrapper>
            </motion.header>
          </div>
          <Portal>
            <Popover.Backdrop className="z-40" />
            <Transition.Fade
              show={isOpen}
              className="fixed w-screen top-0 left-0 right-0 z-50"
            >
              <Popover.Content
                tabIndex={-1}
                className={classNames(
                  'bg-white flex flex-col items-center gap-2 overflow-y-auto max-h-screen',
                  isDesktop ? 'shadow-md' : 'h-[100dvh] inset-0',
                )}
                aria-label={t('action.search')}
              >
                {isDesktop ? (
                  <SearchForm ref={searchInputRef} />
                ) : (
                  <SearchFormMobile showClearButton focusOnMount />
                )}
                <div
                  className={classNames(isDesktop ? 'h-full' : 'h-[100dvh]')}
                >
                  {typeof searchComponent === 'function'
                    ? searchComponent(searchContext)
                    : searchComponent}
                </div>
              </Popover.Content>
            </Transition.Fade>
          </Portal>
        </>
      )}
    </Popover>
  )
}
