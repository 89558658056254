import classNames from 'classnames'
import type { ElementType } from 'react'

import type { BadgeProps, variant, colorVariant, sizeVariant } from './types'
import { Box } from '../Box'

const badgeVariants: { [key in variant]: string } = {
  outline: 'badge--variant-outline',
  ghost: 'badge--variant-ghost',
}

const variantColors: { [key in colorVariant]: string } = {
  primary: 'badge--color-primary',
  critical: 'badge--color-critical',
}

const variantSizes: { [key in sizeVariant]: string } = {
  sm: 'badge--size-sm',
  md: 'badge--size-md',
  lg: 'badge--size-lg',
}

const defaultElement = 'div'

export const Badge = <C extends ElementType = typeof defaultElement>({
  color = 'primary',
  size = 'sm',
  as,
  variant = 'ghost',
  className,
  ...props
}: BadgeProps<C>): JSX.Element => {
  const element: React.ElementType = as || defaultElement
  return (
    <Box
      as={element}
      className={classNames(
        'badge',
        badgeVariants[variant],
        variantColors[color],
        variantSizes[size],
        className,
      )}
      {...props}
    />
  )
}
