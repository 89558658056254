import classNames from 'classnames'
import React, { useMemo } from 'react'

import { addImageSizeParametersToUrl } from '../../utilities'

import { ImageProps } from './types'

export const ImageComponent = ({
  className,
  alt,
  src: providedSrc = '',
  data,
  srcSet: providedSrcSet = data
    ? [
        [{ scale: undefined }, '1x'],
        [{ scale: 2, quality: 80 }, '2x'],
        [{ scale: 3, quality: 60 }, '3x'],
      ]
    : undefined,
  width,
  height,
  draggable,
  loading = 'lazy',
  loader = addImageSizeParametersToUrl,
  children,
  ...props
}: ImageProps): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState(true)

  const src: string = useMemo(() => {
    return data ? loader(data) : providedSrc
  }, [data, providedSrc])

  const srcSet: string | undefined = useMemo(() => {
    if (data && typeof providedSrcSet === 'object') {
      return providedSrcSet
        .map(([options, size]) => `${loader({ ...data, ...options })} ${size}`)
        .join(', ')
    }
    return providedSrcSet as string | undefined
  }, [data, providedSrcSet])

  return (
    <picture>
      {children}
      <img
        className={classNames(
          'transition duration-100 ease-in-out',
          loading === 'lazy' && isLoading ? 'opacity-0' : 'opacity-100',
          className,
        )}
        src={src}
        srcSet={srcSet}
        alt={alt}
        loading={loading}
        width={width}
        height={height}
        draggable={draggable || false}
        onLoad={() => setIsLoading(false)}
        {...props}
      />
    </picture>
  )
}
