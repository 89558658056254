import { SvgProps } from '../types'
import { Icon } from '../Icon'

export interface plusIconProps extends SvgProps {
  variant?: 'light' | 'regular'
}

export const Plus: React.FC<plusIconProps> = ({
  variant = 'regular',
  ...props
}) => (
  <Icon viewBox="0 0 20 20" fill="none" {...props}>
    {variant === 'light' && (
      <>
        <rect
          width="20"
          height="0.907724"
          transform="matrix(1 -1.07396e-06 -1.11767e-06 1 0 9.34064)"
          fill="currentColor"
        />
        <rect
          width="20"
          height="0.927229"
          transform="matrix(1.03338e-06 -1 1 -1.11611e-06 9.73047 20)"
          fill="currentColor"
        />
      </>
    )}
    {variant === 'regular' && (
      <path
        d="M1 9H9M9 9H17M9 9V1M9 9V17"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    )}
  </Icon>
)
