import React, { Suspense } from 'react'
import { Controller } from 'react-hook-form'

import { Skeleton } from '../../Skeleton'

import { InputPhoneNumberProps } from './types'

const BaseInputPhoneNumber = React.lazy(() =>
  import('../../InputPhoneNumber/InputPhoneNumber').then((comp) => ({
    default: comp.InputPhoneNumber,
  })),
)

const PhoneInput = ({
  name,
  errorText,
  ...props
}: InputPhoneNumberProps): JSX.Element => (
  <Controller
    name={name}
    render={({ field, fieldState }) => (
      <Suspense fallback={<Skeleton.Block className="w-full h-10" />}>
        <BaseInputPhoneNumber
          errorText={errorText ?? fieldState.error?.message}
          {...field}
          value={field.value ?? ''}
          {...props}
        />
      </Suspense>
    )}
  />
)

export { PhoneInput }
