import classNames from 'classnames'
import { FC, MouseEventHandler, useCallback, useEffect, useState } from 'react'

import { useMediaQuery } from '../../hooks'
import { Image as ImageComponent } from '../Image'

import { LightboxImage } from './types'

export const ZoomableImage: FC<LightboxImage> = ({
  className,
  data,
  zoomSrc,
  ...props
}) => {
  const isDesktop = useMediaQuery(({ screens }) => `(min-width: ${screens.md})`)
  const [bgCoords, setBgCoords] = useState({ x: 0, y: 0 })
  const [hovered, setHovered] = useState(false)
  const [zoomed, setZoomed] = useState(false)
  const [zoomImage, setZoomImage] = useState(data.src)

  const handleMouseMove: MouseEventHandler<HTMLDivElement> = useCallback(
    (evt) => {
      const { left, top, width, height } =
        evt.currentTarget.getBoundingClientRect()
      const x = ((evt.clientX - left) / width) * 100
      const y = ((evt.clientY - top) / height) * 100
      setBgCoords({ x, y })
    },
    [],
  )

  const handleMouseEnter = useCallback(() => {
    setHovered(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setBgCoords({ x: 0, y: 0 })
    setZoomed(false)
    setHovered(false)
  }, [])

  useEffect(() => {
    if (zoomSrc) {
      setZoomImage
      const image = new Image()
      image.src = zoomSrc
      image.onload = () => {
        setZoomImage(image.src)
      }
    }
  }, [zoomSrc])

  if (!isDesktop) {
    return (
      <div className="flex items-center h-full overflow-hidden">
        <ImageComponent data={data} alt={props.alt || ''} {...props} />
      </div>
    )
  }

  return (
    <div
      className={classNames(
        'h-full flex overflow-hidden bg-white [&_img]:w-full [&_img]:object-cover [&_img]:h-full [&_picture]:w-full justify-center',
        zoomed ? 'cursor-zoom-out' : 'cursor-zoom-in',
        className,
      )}
      onMouseOver={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={() => setZoomed((prev) => !prev)}
    >
      {hovered && (
        <div
          data-testid="zoomed-image"
          className="absolute inset-0"
          style={{
            backgroundPosition: `${bgCoords.x}% ${bgCoords.y}%`,
            backgroundSize: zoomed ? '400%' : '200%',
            backgroundImage: `url(${zoomImage})`,
          }}
        />
      )}
      <ImageComponent
        className="w-full !transition-none"
        data={data}
        alt={props.alt || ''}
        {...props}
      />
    </div>
  )
}
