import { ElementType } from 'react'
import { Box } from '../Box'
import type { BodyProps } from './types'
import { twMerge } from 'tailwind-merge'

const defaultElement = 'div'

export const Body = <TElement extends ElementType = typeof defaultElement>({
  as,
  children,
  className,
}: BodyProps<TElement>): JSX.Element => {
  const element: React.ElementType = as || defaultElement

  return (
    <Box
      as={element}
      className={twMerge('px-6 py-6 overflow-y-auto', className)}
      onPointerDownCapture={(evt: React.PointerEvent) => evt.stopPropagation()}
    >
      {children}
    </Box>
  )
}
