import { useState } from 'react'
import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import { Heading, Text, Image, Button, useMediaQuery } from '@lounge-fe/ui-kit'

import { LayoutProps } from './types'

import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

export const Layout = ({ image, heading, body, onOpen }: LayoutProps) => {
  const isDesktop = useMediaQuery(({ screens }) => `(min-width: ${screens.md})`)

  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-col md:flex-row gap-2 ">
        {image?.src && (
          <div className="aspect-[2/1] w-full md:max-w-[328px] bg-surface-primary">
            <Image
              className="w-full h-full object-cover"
              data={{
                src: image?.src || '',
                width: isDesktop ? 328 : 600,
                height: isDesktop ? 164 : 300,
                crop: 'center',
                quality: 100,
              }}
              srcSet={[
                [{ scale: undefined }, '1x'],
                [{ scale: 2, quality: 80 }, '2x'],
              ]}
              alt=""
              loading="lazy"
            />
          </div>
        )}

        <div
          className={classNames(
            'flex-col bg-surface-secondary px-4 md:px-8 flex md:justify-between w-full items-center',
            image ? 'gap-6 md:flex-row py-6' : 'gap-4 text-center py-10',
          )}
        >
          <div className="flex flex-col gap-2">
            <Heading
              variant="title-four"
              className="capitalize"
              dangerouslySetInnerHTML={{ __html: heading }}
            ></Heading>
            <Text
              variant="body-md"
              dangerouslySetInnerHTML={{ __html: body }}
            />
          </div>
          <div className="w-full md:w-fit">
            <Button
              onClick={onOpen}
              className="w-full md:w-fit whitespace-nowrap"
            >
              {t('action.sign_up')}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout
