import classNames from 'classnames'
import { t } from 'i18next'
import { useCallback, useRef, useState } from 'react'
import { RestError } from '@lounge-fe/network'

import { Icon } from '../../../components/Icon'
import { Text } from '../../../components/Text'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { ActionSheet } from '../../../components/ActionSheet'
import { Image } from '../../../components/Image'
import { IconButton } from '../../../components/IconButton'
import { useClickAwayListener, useDisclosure } from '../../../hooks'
import { IconButtonProps } from '../../../components/IconButton/types'
import { CloseIconButton } from '../../../components/CloseIconButton'
import { Notification } from '../../../components/Notification'
import { useToasts } from '../../ToastProvider'

import { QuickAddProps } from './types'
import { QuickAddForm } from './QuickAddForm'

const OpenButton = ({ className, ...props }: Partial<IconButtonProps>) => (
  <IconButton
    className={classNames(
      'z-10 absolute left-1/2 bottom-2 top-auto p-1 h-8 w-8 bg-[#FFFFFFB3] hover:bg-[#FFFFFFB2] backdrop-blur-[1px] transform -translate-x-1/2',
      className,
    )}
    aria-label={t('product.quick_add')}
    icon={<Icon.Plus />}
    {...props}
  />
)

const preventDefault = (evt: React.MouseEvent) => {
  evt.preventDefault()
  evt.stopPropagation()
}

const DesktopQuickAdd: React.FC<QuickAddProps> = ({
  isLoading,
  variants,
  linkedBottoms = [],
  options = [],
  onSubmit,
  onOpen: parentOnOpen,
  onUnhandledException,
}) => {
  const disclosure = useDisclosure()
  const ref = useRef<HTMLDivElement | null>(null)
  const { toast } = useToasts()

  const onOpen = useCallback(() => {
    disclosure.onOpen()
    parentOnOpen?.()
  }, [disclosure.onOpen, parentOnOpen])

  const handleSubmit = async (...args: Parameters<typeof onSubmit>) => {
    try {
      await onSubmit(...args)
      return { success: true }
    } catch (err) {
      if (err instanceof RestError && 'description' in err.data) {
        toast({
          title: t('general.error'),
          message: err.data.description,
          severity: 'critical',
        })
      } else {
        toast({
          title: t('general.error'),
          message: t('general.error_unexpected'),
          severity: 'critical',
        })

        onUnhandledException(err)
      }
      return { success: false }
    }
  }

  const handleClickAway = useCallback(() => {
    if (disclosure.isOpen) {
      disclosure.onClose()
    }
  }, [disclosure.isOpen, disclosure.onClose])

  useClickAwayListener(ref, handleClickAway)

  return (
    <>
      {disclosure.isOpen && <div className="absolute inset-0" />}
      <div ref={ref}>
        <OpenButton
          className={classNames(
            'transition-opacity',
            disclosure.isOpen ? 'opacity-0' : 'opacity-100',
          )}
          onClick={onOpen}
        />

        <div
          className={classNames(
            'product-card__quick-add',
            disclosure.isOpen && 'product-card__quick-add--open',
          )}
          onClick={preventDefault}
        >
          <div className="product-card__quick-add__title">
            <Icon.Bag width={16} height={16} stroke="black" />
            <Text variant="body-md" className="font-regular">
              {t('product.quick_add')}
            </Text>
            <CloseIconButton
              size="sm"
              className="absolute right-1 top-1 bg-transparent"
              onClick={disclosure.onClose}
              aria-label={t('action.close')}
            />
          </div>
          <QuickAddForm
            options={options}
            variants={variants}
            linkedBottoms={linkedBottoms}
            isLoading={isLoading}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </>
  )
}

const MobileQuickAdd: React.FC<QuickAddProps> = ({
  productTitle,
  productImage,
  price,
  compareAtPrice,
  color,
  isLoading,
  variants,
  linkedBottoms = [],
  options = [],
  onSubmit,
  onOpen: parentOnOpen,
  onUnhandledException,
}) => {
  const timer = useRef<number | null>(null)
  const [error, setError] = useState<string | null>(null)

  const handleSubmit =
    (onClose: () => unknown) =>
    async (...args: Parameters<typeof onSubmit>) => {
      setError(null)
      try {
        const clearTimeout = () => {
          if (timer.current) {
            window.clearTimeout(timer.current)
          }
        }

        clearTimeout()
        await onSubmit(...args)
        clearTimeout()
        timer.current = window.setTimeout(onClose, 1500)
        return { success: true }
      } catch (err) {
        if (err instanceof RestError && 'description' in err.data) {
          setError(err.data.description)
        } else {
          setError(t('general.error_unexpected'))
          onUnhandledException(err)
        }
        return { success: false }
      }
    }

  return (
    <ActionSheet>
      {({ onClose }) => (
        <>
          <ActionSheet.Trigger onClick={(evt) => evt.preventDefault()}>
            <OpenButton />
          </ActionSheet.Trigger>
          <ActionSheet.Content>
            <ActionSheet.Header>
              <div>
                <Text variant="body-md" className="font-regular mb-4">
                  {t('product.select_size')}
                </Text>
                {productImage && (
                  <div className="flex">
                    {productImage.src && (
                      <div className="flex-none">
                        <Image
                          className="rounded-sm aspect-square object-cover bg-surface-primary-default"
                          data={{
                            src: productImage.src,
                            width: 325,
                            height: 483,
                            crop: 'center',
                          }}
                          loading={productImage.loading ?? 'eager'}
                          alt={productImage.alt}
                          width={84}
                          height={84}
                        />
                      </div>
                    )}
                    <div className="flex flex-col justify-center ml-4">
                      <Text variant="body-md">
                        {productTitle}{' '}
                        {color && (
                          <span className="text-subtle">- {color}</span>
                        )}
                      </Text>
                      <Text variant="body-md" className="font-regular">
                        {price}{' '}
                        {compareAtPrice && (
                          <s className="font-regular text-subtle">
                            {compareAtPrice}
                          </s>
                        )}
                      </Text>
                    </div>
                  </div>
                )}
              </div>
            </ActionSheet.Header>
            <ActionSheet.Body className="flex flex-col gap-6">
              {error && (
                <Notification severity="critical">
                  <Text variant="body-sm">{error}</Text>
                </Notification>
              )}
              <QuickAddForm
                options={options}
                variants={variants}
                linkedBottoms={linkedBottoms}
                isLoading={isLoading}
                onSubmit={handleSubmit(onClose)}
              />
            </ActionSheet.Body>
          </ActionSheet.Content>
        </>
      )}
    </ActionSheet>
  )
}

export const QuickAdd: React.FC<QuickAddProps> = (props) => {
  const isDesktop = useMediaQuery(({ screens }) => `(min-width: ${screens.xl})`)

  return isDesktop ? (
    <DesktopQuickAdd {...props} />
  ) : (
    <MobileQuickAdd {...props} />
  )
}
