import { Drawer, useMediaQuery, usePopoverContext } from '@lounge-fe/ui-kit'
import { useDrawerNavigationContext } from './DrawerNavigation'
import type { ContentSize } from './types'
import { useDrawerPaginationContext } from '@lounge-fe/ui-kit/src/components/Drawer/Pagination'
import React from 'react'

const sizeClass: Record<ContentSize, string> = {
  sm: 'sm:max-w-[400px]',
  md: 'sm:max-w-[640px]',
  lg: 'sm:max-w-[800px]',
  xl: 'sm:max-w-[1080px]',
}

export const Content: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isMobile = useMediaQuery(({ screens }) => `(max-width: ${screens.sm})`)
  const { size, resetDrawer } = useDrawerNavigationContext()
  const { isOpen } = usePopoverContext()
  const { resetSteps, activeStep } = useDrawerPaginationContext()

  const handleDrawerAnimationEnd = () => {
    if (!isOpen && activeStep) {
      resetSteps()
      resetDrawer()
    }
  }

  return (
    <Drawer.Content
      direction={isMobile ? 'top' : 'left'}
      className={`w-full ${sizeClass[size]}`}
      transition="max-width .3s cubic-bezier(0.31, 0, 0.13, 1)"
      onAnimationEnd={handleDrawerAnimationEnd}
    >
      <Drawer.PaginationSteps>{children}</Drawer.PaginationSteps>
    </Drawer.Content>
  )
}
