import { Text } from '@lounge-fe/ui-kit'
import { SubNavigationLinksProps } from './types'

export const SubNavigationLinks: React.FC<
  React.PropsWithChildren<SubNavigationLinksProps>
> = ({ children, title }) => {
  return (
    <div className="flex flex-col gap-y-6">
      {title && (
        <Text variant="title-five" className="text-[#AAAAAA]">
          {title}
        </Text>
      )}
      <ul className="flex flex-col items-start gap-y-4">{children}</ul>
    </div>
  )
}
