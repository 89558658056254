import { useTranslation, Trans } from 'react-i18next'
import { Form, Icon, Text } from '@lounge-fe/ui-kit'
import { GenderValue } from '@lounge-fe/validation/src/schema/account'
import { SignUpFormProps } from './types'
import { useExponea } from '@/hooks/useExponea'
import { SignupValues } from '@/hooks/useExponea/types'
import { useShopify } from '@/hooks/useShopify'
import { useState } from 'react'
import { promoSignup } from '@lounge-fe/validation/src/schema/signup'
import { minusYears } from '@/utils/date/date'

export const SignupModalForm = ({ email, onSuccess }: SignUpFormProps) => {
  const { t } = useTranslation()
  const { shop } = useShopify()
  const { events, update } = useExponea()

  const [isLoading, setIsLoading] = useState(false)
  const [isComplete, setIsComplete] = useState(false)

  const handleSubmit = async (values: SignupValues) => {
    events.signup({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      birthday: values.birthday,
      acceptsMarketing: values.acceptsMarketing,
      gender: values.gender,
      tags: ['footer'],
    })

    update({
      domain: shop,
      first_name: values.firstName,
      last_name: values.lastName,
      gender: values.gender,
      birthday: values.birthday
        ? new Date(values.birthday).toISOString()
        : null,
    })

    setIsLoading(true)

    setTimeout(() => {
      setIsLoading(false)
    }, 2000)

    setTimeout(() => {
      setIsComplete(true)
    }, 2001)

    setTimeout(() => {
      onSuccess()
    }, 3000)

    //await onSuccess()
  }

  return (
    <Form
      id="modal-sign-up-form"
      validationSchema={promoSignup}
      defaultValues={{ email }}
      onSubmit={handleSubmit}
      className="flex flex-col gap-6"
    >
      <Form.Input
        required
        labelPosition="inside"
        name="email"
        label={t('account.email_address')}
      />
      <div className="grid gap-4 md:grid-cols-2">
        <Form.Input
          required
          labelPosition="inside"
          name="firstName"
          label={t('account.first_name')}
        />
        <Form.Input
          required
          labelPosition="inside"
          name="lastName"
          label={t('account.last_name')}
        />
        <Form.Input
          labelPosition="inside"
          name="birthday"
          type="date"
          label={t('account.date_of_birth')}
          max={minusYears(16, new Date()).toISOString().split('T')[0]}
        />
        <Form.Select
          name="gender"
          placeholder={t('account.gender')}
          options={[
            {
              label: t('account.female'),
              value: GenderValue.Female,
            },
            {
              label: t('account.male'),
              value: GenderValue.Male,
            },
            {
              label: t('account.prefer_not_to_say'),
              value: GenderValue.PreferNotToSay,
            },
          ]}
        />
      </div>
      <Form.Checkbox
        name="acceptsMarketing"
        label={t('account.sms_email_marketing_opt_in')}
        className="justify-start"
      />
      {isComplete ? (
        <>
          <Form.Submit className="w-full text-white">
            <Icon.Tick fill="white" />
          </Form.Submit>
        </>
      ) : (
        <>
          <Form.Submit isLoading={isLoading} className="w-full">
            {t('action.sign_up')}
          </Form.Submit>
        </>
      )}

      <Text variant="body-sm">
        <Trans
          i18nKey="account.privacy_policy_statement"
          components={{ a: <a /> }}
        />
      </Text>
    </Form>
  )
}
