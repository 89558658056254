import {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useMemo,
} from 'react'

import {
  ToggleGroupChangeHandler,
  ToggleGroupContextState,
  ToggleGroupProps,
} from './types'

const ToggleGroupContext = createContext<ToggleGroupContextState>(
  {} as ToggleGroupContextState,
)

const multiChangeHandler = (
  currentValue: unknown[],
  changedValue: unknown,
  onChange: ToggleGroupChangeHandler,
  preventEmpty: boolean,
) => {
  if (
    preventEmpty &&
    currentValue.length === 1 &&
    currentValue[0] === changedValue
  ) {
    return onChange([changedValue])
  } else {
    return onChange(
      currentValue.includes(changedValue)
        ? currentValue.filter((val) => val !== changedValue)
        : [...currentValue, changedValue],
    )
  }
}

const singleChangeHandler = (
  currentValue: unknown | undefined,
  changedValue: unknown,
  onChange: ToggleGroupChangeHandler,
  preventEmpty: boolean,
) =>
  preventEmpty
    ? onChange(changedValue)
    : onChange(currentValue === changedValue ? undefined : changedValue)

export const ToggleGroup = forwardRef<HTMLDivElement, ToggleGroupProps>(
  (
    {
      children,
      value,
      onChange: providedOnChange,
      preventEmpty = false,
      ...props
    },
    ref,
  ) => {
    const onChange = useCallback(
      (changedValue: unknown) => {
        Array.isArray(value)
          ? multiChangeHandler(
              value,
              changedValue,
              providedOnChange,
              preventEmpty,
            )
          : singleChangeHandler(
              value,
              changedValue,
              providedOnChange,
              preventEmpty,
            )
      },
      [value, providedOnChange],
    )

    const context = useMemo<ToggleGroupContextState>(
      () => ({
        selectedValue: value,
        onChange,
      }),
      [value, onChange],
    )

    return (
      <ToggleGroupContext.Provider value={context}>
        <div role="group" ref={ref} {...props}>
          {children}
        </div>
      </ToggleGroupContext.Provider>
    )
  },
)

ToggleGroup.displayName = 'ToggleGroup'

export const useToggleGroupContext = () => useContext(ToggleGroupContext)
