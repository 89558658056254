import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const ArrowLeft: React.FC<SvgProps> = (props) => {
  return (
    <Icon fillRule="evenodd" clipRule="evenodd" viewBox="0 0 12 12" {...props}>
      <path
        d="M5.18128 10.0989L1.08222 5.99982M1.08222 5.99982L5.18128 1.90076M1.08222 5.99982L10.9199 5.99982"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
