import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Minus: React.FC<SvgProps> = (props) => (
  <Icon viewBox="0 0 20 20" fill="none" {...props}>
    <rect
      width="20"
      height="0.907724"
      transform="matrix(1 -1.07396e-06 -1.11767e-06 1 0 9.34064)"
      fill="currentColor"
    />
  </Icon>
)
