import useEmblaCarousel from 'embla-carousel-react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'

import { ArticleCard, Carousel, Heading, Wrapper } from '@lounge-fe/ui-kit'

import { ArticlesProps } from './types'
import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'

export const RelatedPosts: ShopifySectionComponent<ArticlesProps> = ({
  data,
}) => {
  const { articles = [], title = '' } = data ?? {}
  const [emblaRef, embla] = useEmblaCarousel(
    {
      align: 'start',
      containScroll: 'trimSnaps',
      skipSnaps: true,
    },
    [WheelGesturesPlugin()],
  )

  return (
    <Wrapper>
      <hr className="pb-8" />
      <Carousel emblaRef={emblaRef} embla={embla}>
        <div className="flex justify-between gap-x-4">
          {title && (
            <Heading variant="title-three" className="mb-4">
              {title}
            </Heading>
          )}
          <Carousel.Arrows className="justify-end pb-10" />
        </div>
        <Carousel.Viewport>
          <Carousel.Container className="gap-x-4 auto-cols-[calc(100%-12px)] sm:auto-cols-[calc(50%-12px)] lg:auto-cols-[calc(25%-12px)] pb-8">
            {articles.map((article, idx) => {
              const { title, url, tags, image } = article
              return (
                <Carousel.Slide key={idx} index={idx}>
                  <ArticleCard href={url}>
                    <ArticleCard.Image
                      data={{
                        src: image,
                        width: 345,
                        height: 345,
                        crop: 'center',
                      }}
                      alt={title}
                      className="w-full"
                    />
                    <ArticleCard.Details category={tags} title={title} />
                  </ArticleCard>
                </Carousel.Slide>
              )
            })}
          </Carousel.Container>
        </Carousel.Viewport>
      </Carousel>
    </Wrapper>
  )
}
