import { useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'

import { storefrontClient } from '@/lib'
import { parseGID } from '@/utils/parseGID'
import { ProductHit } from '../../types/ProductHit'
import type { Cache } from './types'
import { useProductsVariants } from '../useProductsVariants/useProductsVariants'
import {
  getProductData,
  GetProductDataExtensionResponse,
  GetProductDataResponseNode,
} from '../../queries/getProductData'
import { createStorefrontExtendedProductHit } from '../../utils/createExtendedProductHit'
import { useShopify } from '@/hooks/useShopify'

const filterProductIds = (productIds: number[], data: Cache) =>
  productIds.filter((id) => !data.has(id))

const id = {
  toGid: (id: number) => `gid://shopify/Product/${id}`,
  fromGid: (gid: string) => Number(parseGID(gid).childObjectId),
}

const fetchProducts = async (
  gids: string[],
): Promise<GetProductDataResponseNode[]> => {
  const response =
    await storefrontClient.request<GetProductDataExtensionResponse>(
      getProductData,
      { variables: { ids: gids } },
    )

  return response.data?.nodes.filter(Boolean) ?? []
}

const cacheNewItems =
  (nodes: GetProductDataResponseNode[]) => (previous: Cache) => {
    const newCache = new Map(previous)
    nodes.forEach((node) => {
      newCache.set(id.fromGid(node.id), node)
    })
    return newCache
  }

export const useAlgoliaDataExtension = <TIncludeVariants extends boolean>(
  hits: ProductHit[],
  includeVariants: TIncludeVariants,
) => {
  const { settings } = useShopify()
  const [cache, setCache] = useState(
    new Map<number, GetProductDataResponseNode>(),
  )

  useEffect(() => {
    const update = async () => {
      const productIds = hits.map((hit) => Number(hit.id))
      const filteredIds = filterProductIds(productIds, cache).map(id.toGid)
      if (filterProductIds.length === 0) return

      try {
        const response = await fetchProducts(filteredIds)
        setCache(cacheNewItems(response))
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    if (hits?.length > 0) {
      update()
    }
  }, [hits])

  const products = useMemo(
    () =>
      hits.map((hit) =>
        createStorefrontExtendedProductHit(hit, cache.get(Number(hit.id))),
      ),
    [cache, hits],
  )

  const productsWithVariants = useProductsVariants<TIncludeVariants>(
    products,
    includeVariants,
    settings?.stop_selling_quantity ?? undefined,
  )

  return productsWithVariants
}
