import { Button, Tile } from '@lounge-fe/ui-kit'

import { NavigationTileProps } from './types'

export const NavigationTile = ({
  aspectRatio,
  href,
  text,
  buttonText = 'Shop now',
  image,
  overlay = true,
  showButton,
  showImage = true,
  subtitle,
}: NavigationTileProps) => {
  return (
    <Tile
      as="a"
      href={href}
      size="md"
      aspectRatio={aspectRatio ?? '1/1'}
      contentPlacement="inside"
      overlay={overlay}
    >
      {showImage && image?.src && (
        <Tile.Image
          data={{
            src: image.src,
            width: image.width,
            height: image.height,
            crop: 'center',
          }}
        />
      )}
      <Tile.Content>
        <Tile.Title>{text}</Tile.Title>
        {subtitle && <Tile.Subtitle>{subtitle}</Tile.Subtitle>}

        {showButton && (
          <Tile.Actions>
            <Button variant="solid" color="secondary" size="sm" tabIndex={-1}>
              {buttonText}
            </Button>
          </Tile.Actions>
        )}
      </Tile.Content>
    </Tile>
  )
}
