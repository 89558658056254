import classNames from 'classnames'
import type { IconLinkProps } from './types'
import { Icon } from '../Icon'
import { ElementType } from 'react'
import { Box } from '../Box'

const defaultElement = 'div'

export const IconLink = <TElement extends ElementType = typeof defaultElement>({
  as,
  children,
  className,
  icon,
  iconColor,
  iconPosition,
  onClick,
}: IconLinkProps<TElement>) => {
  const element: React.ElementType = as || defaultElement

  const iconPositionClass =
    iconPosition === 'start' ? 'flex-row' : 'flex-row-reverse'

  return (
    <Box
      as={element}
      className={classNames(
        'flex items-center gap-x-2',
        iconPositionClass,
        className,
      )}
      onClick={onClick}
    >
      <Icon.ChevronUp
        color={iconColor ?? '#111111'}
        className={classNames(
          'w-3 h-3',
          icon === 'chevronLeft' ? '-rotate-90' : 'rotate-90',
        )}
      />
      {children}
    </Box>
  )
}
