import classNames from 'classnames'
import { useMemo } from 'react'

import { clamp } from '../../utilities'

import { ProgressBarProps } from './types'

const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  max = 100,
  min = 0,
  className = 'bg-black',
  ...props
}) => {
  const percent = useMemo(
    () => Math.round(clamp((value / max) * 100, min, 100)),
    [value, max, min],
  )

  return (
    <div
      role="progressbar"
      aria-valuenow={value}
      aria-valuemin={min}
      aria-valuemax={max}
      className={classNames('absolute inset-0 transition-transform', className)}
      style={{ transform: `translateX(-${100 - percent}%)` }}
      {...props}
    />
  )
}

export { ProgressBar }
