import classNames from 'classnames'
import { HTMLAttributes } from 'react'

export const Block = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={classNames(
      'animate-pulse bg-surface-primary-default',
      className,
    )}
    aria-hidden
    {...props}
  />
)
