import { Drawer, useMediaQuery } from '@lounge-fe/ui-kit'
import { DrawerNavigation } from '..'
import { useDrawerNavigationContext } from '../DrawerNavigation'
import React from 'react'

const MobileHeader = React.lazy(async () => ({
  default: (await import('./MobileHeader')).MobileHeader,
}))

export const Header: React.FC = () => {
  const { activeItem, resetDrawer } = useDrawerNavigationContext()
  const isMobile = useMediaQuery(({ screens }) => `(max-width: ${screens.sm})`)

  return (
    <Drawer.Header className="sticky top-0 bg-white z-10 sm:px-10">
      {isMobile ? (
        <MobileHeader />
      ) : (
        <DrawerNavigation.Link
          as="button"
          iconPosition="start"
          icon="chevronLeft"
          onClick={resetDrawer}
          paginate="back"
          variant="title-six"
        >
          {activeItem}
        </DrawerNavigation.Link>
      )}
    </Drawer.Header>
  )
}
