import type { Cart, ICartPlugin, Item } from '../../types'
import CartPlugin from '../CartPlugin'

import { GiftWrapPluginOptions } from './types'

export class GiftWrapPlugin extends CartPlugin implements ICartPlugin {
  private productId: number
  private productPrice: number

  constructor({ productId, productPrice, enabled }: GiftWrapPluginOptions) {
    super(
      Boolean(
        enabled &&
          typeof productId !== 'undefined' &&
          typeof productPrice !== 'undefined',
      ),
    )
    this.productId = productId!
    this.productPrice = productPrice!
  }

  setGiftWrap = (giftWrap: boolean): Promise<Cart> =>
    this.manager.update({
      updates: { [this.productId]: giftWrap ? 1 : 0 },
    })

  onClear = (cart: Readonly<Cart>): Cart => {
    cart.meta.giftWrap = false
    return cart
  }

  getPrice = (): number => this.productPrice

  onRemoveItems = (cart: Readonly<Cart>): Promise<Cart> => {
    if (cart.items.length === 1 && cart.items[0].id === this.productId) {
      return this.manager.removeItem(this.productId)
    }
    return Promise.resolve(cart)
  }

  onFormatCart = (cart: Cart): Cart => {
    const newCart = { ...cart }
    newCart.meta.giftWrap = Boolean(
      newCart.items.find(({ id }) => id === this.productId),
    )

    return newCart
  }
}
