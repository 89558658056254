import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import { Carousel, Image } from '@lounge-fe/ui-kit'
import classNames from 'classnames'
import useEmblaCarousel from 'embla-carousel-react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'

interface ImageCarouselData {
  images: { src: string; alt: string; height: string; width: string }[]
}

export const ImageCarousel: ShopifySectionComponent<ImageCarouselData> = ({
  data: { images = [] } = {},
}) => {
  const [emblaRef, embla] = useEmblaCarousel(
    {
      align: 'center',
      containScroll: 'trimSnaps',
      skipSnaps: true,
      active: true,
      loop: true,
    },
    [WheelGesturesPlugin()],
  )

  return (
    <Carousel emblaRef={emblaRef} embla={embla}>
      <Carousel.Viewport className={classNames('m-auto')}>
        <Carousel.Container className="items-center auto-cols-[80%] sm:auto-cols-[60%] lg:auto-cols-[40%] xl:auto-cols-[30%]">
          {images
            .filter((image) => image && image?.src)
            .map((image, idx) => {
              const aspectRatio =
                (parseInt(image.height) / parseInt(image.width)) * 100
              const width = 600
              const height = (width * aspectRatio) / 100
              return (
                <Carousel.Slide
                  key={idx}
                  index={idx}
                  className="px-2 md:px-2 xl:px-4"
                >
                  <Image
                    className="w-full"
                    data={{
                      src: image.src,
                      width,
                      height,
                      quality: 90,
                      crop: 'center',
                    }}
                    alt={image.alt}
                  />
                </Carousel.Slide>
              )
            })}
        </Carousel.Container>
      </Carousel.Viewport>
    </Carousel>
  )
}
