import { zodResolver } from '@hookform/resolvers/zod'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'

import { FormProps } from './types'

export const Form = <TValues extends FieldValues = any>({
  className,
  children,
  onSubmit,
  validationSchema,
  mode = 'onBlur',
  'data-testid': testId,
  id,
  autoComplete,
  method,
  ...options
}: FormProps<TValues>): JSX.Element => {
  const methods = useForm<TValues>({
    resolver: validationSchema ? zodResolver(validationSchema) : undefined,
    mode,
    ...options,
  })

  return (
    <FormProvider {...methods}>
      <form
        className={className}
        onSubmit={(evt) => {
          evt.stopPropagation()
          return methods.handleSubmit((data) => onSubmit(data, methods, evt))(
            evt,
          )
        }}
        data-testid={testId}
        id={id}
        autoComplete={autoComplete}
        method={method}
      >
        {typeof children === 'function' ? children(methods) : children}
      </form>
    </FormProvider>
  )
}
