import { Drawer, useMediaQuery } from '@lounge-fe/ui-kit'
import React from 'react'

const MobileHeader = React.lazy(async () => ({
  default: (await import('./MobileHeader')).MobileHeader,
}))

export const Header: React.FC = () => {
  const isMobile = useMediaQuery(({ screens }) => `(max-width: ${screens.sm})`)

  if (isMobile) {
    return <MobileHeader />
  }

  return <Drawer.Header className="justify-end px-8" />
}
