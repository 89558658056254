import { useTranslation } from 'react-i18next'
import {
  Logo,
  Flex,
  IconButton,
  Icon,
  Popover,
  Transition,
  Portal,
} from '@lounge-fe/ui-kit'
import { useSearchContext } from '../SearchProvider'
import { useShopify } from '../../../../../hooks/useShopify'

import { SearchForm } from './SearchForm'
import { HeaderProps } from './types'
import classNames from 'classnames'

export const Header = ({
  actionsComponent,
  searchComponent,
  showSearchForm = true,
  className,
  ...props
}: HeaderProps) => {
  const { t } = useTranslation()
  const { routes } = useShopify()
  const searchContext = useSearchContext()

  return (
    <Flex
      className={classNames(
        'flex sticky top-0 flex-col bg-white h-[var(--mobile-header-height)] flex-shrink-0 z-10',
        className,
      )}
      {...props}
    >
      <Flex className="items-center justify-center flex-1 px-4">
        <Flex className="flex-1">
          <Popover.Trigger>
            {({ isOpen }) => (
              <IconButton
                type="button"
                aria-label={isOpen ? t('action.close') : t('action.open')}
                size="md"
                icon={
                  isOpen ? (
                    <Icon.Close className="!w-4 !h-4" />
                  ) : (
                    <Icon.BurgerMenu className="!w-4 !h-4" />
                  )
                }
              />
            )}
          </Popover.Trigger>
        </Flex>
        <a href={routes.root_url} title="Lounge Underwear">
          <Logo width={108} />
        </a>
        <Flex className="items-center justify-end flex-1 h-9">
          <Transition.Fade show={Boolean(actionsComponent)}>
            {actionsComponent}
          </Transition.Fade>
        </Flex>
      </Flex>

      <Popover>
        {({ onOpen, isOpen }) => (
          <>
            {showSearchForm && (
              <Flex aria-hidden={isOpen}>
                <SearchForm onClick={onOpen} />
              </Flex>
            )}

            {isOpen && (
              <Portal>
                <Popover.Content
                  tabIndex={-1}
                  className="fixed inset-0 bg-white z-50 max-h-screen overflow-y-auto"
                  aria-label={t('action.search')}
                >
                  <SearchForm
                    showClearButton
                    className="sticky top-0"
                    focusOnMount
                  />
                  {typeof searchComponent === 'function'
                    ? searchComponent(searchContext)
                    : searchComponent}
                </Popover.Content>
              </Portal>
            )}
          </>
        )}
      </Popover>
    </Flex>
  )
}
