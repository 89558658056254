import { Drawer, Link } from '@lounge-fe/ui-kit'
import { DrawerNavigation } from '..'
import { useDrawerNavigationContext } from '../DrawerNavigation'
import type { HomeNavigationProps } from './types'
import { Header } from './Header'
import { CountrySelector } from './CountrySelector'

export const HomeNavigation: React.FC<HomeNavigationProps> = ({
  menuItems,
  drawerFooterMenu,
}) => {
  const { setActiveItem } = useDrawerNavigationContext()

  return (
    <>
      <Header />
      <Drawer.Body as="nav" className="sm:px-10 sm:py-10">
        <ul className="flex flex-col items-start gap-y-8">
          {menuItems
            ? menuItems.map(({ title }) => (
                <DrawerNavigation.Link
                  key={title}
                  as="button"
                  className="w-full"
                  onClick={() => {
                    setActiveItem(title)
                  }}
                  paginate="next"
                  variant="title-five"
                >
                  {title}
                </DrawerNavigation.Link>
              ))
            : undefined}
        </ul>
        <hr className="my-10" />
        <ul className="flex flex-col items-start gap-y-4">
          {drawerFooterMenu
            ? drawerFooterMenu.map(({ title, url }) => (
                <Link
                  href={url}
                  key={title}
                  className="w-full"
                  variant="body-sm"
                >
                  {title}
                </Link>
              ))
            : undefined}
          <CountrySelector />
        </ul>
      </Drawer.Body>
    </>
  )
}
