import { cloneElement, useCallback, MouseEventHandler } from 'react'

import { usePopoverContext } from './Popover'
import { TriggerProps } from './types'

export const Trigger = <E extends HTMLElement>({
  children,
  enableHoverTrigger,
  onClick: parentOnClick,
  ...props
}: TriggerProps<E>) => {
  const context = usePopoverContext()

  const {
    isOpen,
    onOpen,
    onClose,
    setOpenReason,
    triggerId,
    contentId,
    popoverId,
  } = context

  const handleClick: MouseEventHandler<E> = useCallback(
    (evt) => {
      if (isOpen) {
        onClose()
        setOpenReason(null)
      } else {
        onOpen()
        setOpenReason('click')
      }
      parentOnClick?.(evt)
    },
    [isOpen],
  )

  const handleMouseEnter = useCallback(() => {
    onOpen()
    setOpenReason('hover')
  }, [])

  return cloneElement(
    typeof children === 'function' ? children(context) : children,
    {
      id: triggerId,
      'aria-controls': contentId,
      'aria-haspopup': 'dialog',
      'aria-expanded': isOpen,
      onClick: handleClick,
      onMouseEnter: enableHoverTrigger ? handleMouseEnter : undefined,
      'data-ui-state': isOpen ? 'open' : '',
      'data-popover-id': popoverId,
      ...props,
    },
  )
}
