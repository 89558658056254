import { useContext, useMemo, createContext } from 'react'

import { ProductCardProps } from './types'
import classNames from 'classnames'

const ProductCardContext = createContext<{ href: string }>({
  href: '',
})

export const ProductCard = ({
  href,
  onClick,
  aspectRatio = '2/3',
  ...props
}: ProductCardProps): JSX.Element => {
  const context = useMemo(() => ({ href }), [href])
  return (
    <ProductCardContext.Provider value={context}>
      <div
        onClick={onClick}
        className={classNames(
          'product-card',
          aspectRatio == '1/1' && 'product-card--square',
        )}
        {...props}
      />
    </ProductCardContext.Provider>
  )
}

export const useProductCardContext = () => useContext(ProductCardContext)
