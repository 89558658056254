import classNames from 'classnames'
import { forwardRef, useId } from 'react'

import { CheckboxProps } from './types'

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      className,
      id: providedId,
      name,
      value,
      checked,
      disabled,
      label,
      onChange,
      rounded,
      readOnly,
      ...props
    },
    ref,
  ) => {
    const generatedId = useId()
    const id = providedId ?? generatedId

    return (
      <div
        className={classNames(
          'checkbox',
          {
            'checkbox--inactive': disabled,
            'checkbox--rounded': rounded,
          },
          className,
        )}
        {...props}
      >
        <input
          type="checkbox"
          onChange={onChange}
          id={id}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          ref={ref}
          readOnly={readOnly}
        />
        <label htmlFor={id}>{label}</label>
      </div>
    )
  },
)

Checkbox.displayName = 'Checkbox'
