import { ElementType, isValidElement } from 'react'
import classnames from 'classnames'

import { getExternalLinkProps } from '../../utilities'
import { Icon } from '../Icon'
import { Box } from '../Box'
import { ClassMapping } from '../../types/ClassMapping'

import { ButtonColor, ButtonProps, ButtonSize, ButtonVariant } from './types'

const defaultElement = 'button'
const defaultColor = 'primary'
const defaultVariant = 'solid'
const defaultSize = 'md'

const sizeClasses: ClassMapping<ButtonSize, typeof defaultSize> = {
  sm: 'button--size-sm',
  lg: 'button--size-lg',
}

const variantClasses: ClassMapping<ButtonVariant, typeof defaultVariant> = {
  outline: 'button--variant-outline',
  ghost: 'button--variant-ghost',
}

const colorClasses: ClassMapping<ButtonColor, typeof defaultColor> = {
  secondary: 'button--color-secondary',
}

export const Button = <E extends ElementType = typeof defaultElement>({
  startIcon,
  endIcon,
  className,
  external = false,
  isLoading = false,
  isSuccess = false,
  isActive = false,
  disabled = false,
  variant = defaultVariant,
  color = defaultColor,
  size = defaultSize,
  children,
  as,
  href,
  testId,
  ...props
}: ButtonProps<E>): JSX.Element => {
  const internalProps = external ? getExternalLinkProps() : {}
  const isDisabled = isLoading || disabled

  return (
    <Box
      as={href && !isDisabled ? 'a' : defaultElement}
      href={href}
      className={classnames(
        'button',
        {
          'button--loading': isLoading,
          'button--disabled': isDisabled,
          'button--active': isActive,
        },
        variant !== defaultVariant && variantClasses[variant],
        size !== defaultSize && sizeClasses[size],
        color !== defaultColor && colorClasses[color],
        className,
      )}
      translate="no"
      disabled={isDisabled}
      data-testid={testId}
      {...internalProps}
      {...props}
    >
      <>
        {!isLoading && (
          <>
            {isValidElement(startIcon) && (
              <div className="button__start-icon">{startIcon}</div>
            )}
            {children}
            {isValidElement(endIcon) && (
              <div className="button__end-icon">{endIcon}</div>
            )}
          </>
        )}

        {isLoading && !isSuccess && <Icon.Loading spin className="w-6 h-6" />}
        {isSuccess && <Icon.Check />}
      </>
    </Box>
  )
}
