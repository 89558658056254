import { FormEventHandler, useCallback, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import {
  Input,
  Icon,
  Transition,
  CloseIconButton,
  usePopoverContext,
} from '@lounge-fe/ui-kit'
import { SearchFormProps } from '../types'
import { useSearchContext } from '../SearchProvider'

export const SearchForm = ({
  onClick,
  showClearButton,
  className,
  focusOnMount = false,
}: SearchFormProps) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLInputElement | null>(null)
  const { search, setSearch, onClear } = useSearchContext()
  const { onClose } = usePopoverContext()

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback((evt) => {
    evt.preventDefault()
  }, [])

  const handleChange: FormEventHandler<HTMLInputElement> = useCallback(
    (evt) => setSearch(evt.currentTarget.value),
    [],
  )

  useEffect(() => {
    if (focusOnMount) {
      ref.current?.focus()
    }
  }, [focusOnMount])

  return (
    <form className="w-full" onSubmit={handleSubmit}>
      <Input
        className={classNames(
          'color-[#111111] w-full',
          String.raw`[&_.input-group\_\_control]:px-0`,
          String.raw`[&_.input-group\_\_control]:px-2 [&_.input-group\_\_control]:!rounded-[0]`,
          String.raw`[&_.input-group\_\_control]:!border-x-0 [&_.input-group\_\_control]:!border-y-1 [&_.input-group\_\_control]:!border-[#F0F0F0]`,
          '[&_input]:px-0 [&_input]:py-4',
          className,
        )}
        placeholder={t('action.search_products')}
        aria-label={t('action.search_products')}
        id="search-products"
        onClick={onClick}
        ref={ref}
        startAdornment={<Icon.Search width={16} height={16} />}
        endAdornment={
          <Transition.Fade show={showClearButton || Boolean(search)}>
            <CloseIconButton
              type="reset"
              aria-label={t('action.clear')}
              onClick={() => {
                onClose()
                onClear()
              }}
            />
          </Transition.Fade>
        }
        value={search}
        onChange={handleChange}
      />
    </form>
  )
}
