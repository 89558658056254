import { Drawer, usePopoverContext } from '@lounge-fe/ui-kit'
import { useDrawerNavigationContext } from '../DrawerNavigation'
import type { SubNavigationProps } from './types'
import { useEffect, useMemo } from 'react'
import { SubNavigationColumn } from './SubNavigationColumn'
import { Header } from './Header'

export const SubNavigation: React.FC<SubNavigationProps> = ({
  menuItems,
  tiles,
}) => {
  const { activeItem, setSize, size } = useDrawerNavigationContext()
  const { isOpen } = usePopoverContext()

  const filteredItem = useMemo(() => {
    if (activeItem && menuItems) {
      const filteredItem = menuItems.filter(
        ({ title }) => title === activeItem,
      )[0]

      if (!filteredItem) {
        console.error('No SubNavigation item found for: ', activeItem)
      }

      return filteredItem
    }
  }, [activeItem])

  useEffect(() => {
    if (activeItem && isOpen && filteredItem && filteredItem.sub_links) {
      switch (filteredItem.sub_links.length) {
        case 2:
          setSize('lg')
          break
        case 3:
          setSize('xl')
          break
        default:
          setSize('md')
          break
      }
    }
  }, [activeItem, filteredItem, size])

  return (
    <>
      <Header />
      <Drawer.Body as="nav" className="sm:px-10 sm:py-10">
        {menuItems
          ? menuItems.map(({ title, sub_links }, idx) => (
              <SubNavigationColumn
                key={title}
                subMenuIndex={idx}
                columnTitle={title}
                subLinks={sub_links}
                tiles={tiles}
              />
            ))
          : undefined}
      </Drawer.Body>
    </>
  )
}
