import { useEffect, useRef, useState } from 'react'
import { useAnimate, AnimationPlaybackControls } from 'framer-motion'

export const useVideoProgress = (
  videoRef: React.RefObject<HTMLVideoElement>,
  inView: boolean,
) => {
  const [scope, animate] = useAnimate()
  const [duration, setDuration] = useState(0)
  const [paused, setPaused] = useState(false)
  const animation = useRef<AnimationPlaybackControls>()

  useEffect(() => {
    videoRef.current?.addEventListener('ended', () => {
      animation.current?.pause()
      videoRef.current?.pause()
      setPaused(true)
    })
  }, [])

  const play = () => {
    animation.current?.play()
    videoRef.current?.play()
    setPaused(false)
  }

  const pause = () => {
    animation.current?.pause()
    videoRef.current?.pause()
    setPaused(true)
  }

  useEffect(() => {
    const handleMetadataLoaded = () => setDuration(videoRef.current!.duration)
    videoRef.current?.addEventListener('loadedmetadata', handleMetadataLoaded)
    return () => {
      videoRef.current?.removeEventListener(
        'loadedmetadata',
        handleMetadataLoaded,
      )
    }
  }, [])

  useEffect(() => {
    const handleSpacebarPress = (evt: any) => {
      if (evt.code == 'Space') {
        evt.preventDefault()

        if (paused) {
          play()
        } else {
          pause()
        }
      }
    }
    if (inView) {
      window.addEventListener('keydown', handleSpacebarPress)
      return () => {
        window.removeEventListener('keydown', handleSpacebarPress)
      }
    }
  }, [paused, inView])

  return { scope, animation, duration, pause, play, paused }
}
