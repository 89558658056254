import { createCompoundComponent } from '../../utilities'
import { Trigger } from '../Popover/Trigger'
import { Group } from '../Popover/Group'
import { Drawer as BaseDrawer } from './Drawer'
import { Body } from './Body'
import { Content } from './Content'
import { Header } from './Header'
import { Pagination } from './Pagination'
import { PaginationSteps } from './PaginationSteps'

export const Drawer = createCompoundComponent(BaseDrawer, {
  Content,
  Header,
  Body,
  Trigger,
  Group,
  Pagination,
  PaginationSteps,
})
