import { Icon, Modal, Text } from '@lounge-fe/ui-kit'
import { useCurrentStore } from '../../hooks/useCurrentStore'
import { TriggerProps } from './types'
import React from 'react'

export const Trigger: React.FC<TriggerProps> = ({
  options,
  renderStore = (store) => store.shortName ?? store.name,
}) => {
  const currentStore = useCurrentStore()

  return (
    <Modal.Trigger>
      <button className="align-self-start flex gap-x-2 items-center">
        <currentStore.icon className="w-4 h-4 rounded-circle border" />
        <Text as="span" variant="body-sm" className="text-[#111111]">
          {renderStore(currentStore)}
        </Text>
        {options?.showLanguage ? (
          <>
            <Text as="span" className="text-[#111111]" variant="body-xs">
              {'|'}
            </Text>
            <Text as="span" className="text-[#888888]" variant="body-sm">
              {currentStore.language}
            </Text>
          </>
        ) : undefined}
        <Icon.ChevronDown className="w-[6px] h-[6px]" />
      </button>
    </Modal.Trigger>
  )
}
