import { createElement, useCallback, useEffect, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client'

const css = `
  button {
    all: unset;
    background: transparent;
  }
  button:hover {
    cursor: pointer;
  }
  .video-circle {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
`

const radius = 11
const circumference = radius * 2 * Math.PI

const Template = ({ videoElement }: { videoElement: HTMLVideoElement }) => {
  const [playing, setPlaying] = useState(false)
  const circle = useRef<SVGCircleElement | null>(null)

  const progressLoop = useCallback(() => {
    if (videoElement.paused) {
      setPlaying(false)
      return
    }

    setPlaying(true)

    const percent = videoElement.currentTime / videoElement.duration

    if (circle.current) {
      circle.current.style.strokeDashoffset = (
        circumference -
        percent * circumference
      ).toString()
    }

    requestAnimationFrame(progressLoop)
  }, [])

  const pausePlay = useCallback(() => {
    if (videoElement.paused) {
      videoElement.play()
    } else {
      videoElement.pause()
    }
  }, [])

  useEffect(() => {
    progressLoop()

    videoElement.addEventListener('play', progressLoop)
    videoElement.addEventListener('click', pausePlay)
    return () => {
      videoElement.removeEventListener('play', progressLoop)
      videoElement.removeEventListener('click', pausePlay)
    }
  }, [])

  return (
    <>
      <style>{css}</style>
      <button onClick={pausePlay}>
        <svg
          width="40"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {playing && (
            <g>
              <rect
                x="8.39999"
                y="7.20001"
                width="1.8"
                height="9.6"
                fill="white"
              />
              <rect
                x="13.8"
                y="7.20001"
                width="1.8"
                height="9.6"
                fill="white"
              />
            </g>
          )}
          {!playing && (
            <path
              d="M16.3018 12L10.3018 16.33V7.67001L16.3018 12Z"
              fill="white"
            />
          )}
          <circle cx="12" cy="12" r="11" strokeWidth="2" />
          <circle
            ref={circle}
            className="video-circle"
            cx="12"
            cy="12"
            r={radius}
            strokeWidth="2"
            stroke="white"
            pathLength="1"
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={circumference}
          />
        </svg>
      </button>
    </>
  )
}

class VideoPlayer extends HTMLElement {
  private shadow!: ShadowRoot

  connectedCallback() {
    this.shadow = this.attachShadow({ mode: 'closed' })
    const root = document.createElement('template')
    this.shadow.appendChild(root.content.cloneNode(true))
    const template = createRoot(this.shadow)
    template.render(
      createElement(Template, {
        videoElement: this.parentElement!.querySelector('video')!,
      }),
    )
  }
}

customElements.define('video-play-btn', VideoPlayer)
