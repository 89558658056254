import { createContext, useContext, useMemo } from 'react'

import { SearchProvider } from './SearchProvider'

import type { NavigationContextState, RootNavigationProps } from './types'
import { DrawerNavigation } from '../DrawerNavigation'

const initialContextState: NavigationContextState = {
  hasScrollAnimation: {
    mobile: true,
    desktop: true,
  },
  searchComponent: null,
  basketComponent: null,
}

const NavigationContext =
  createContext<NavigationContextState>(initialContextState)

export const NewNavigation = ({
  onSearchSubmit,
  hasScrollAnimation = initialContextState.hasScrollAnimation,
  searchComponent,
  basketComponent,
  children,
}: RootNavigationProps) => {
  const context = useMemo<NavigationContextState>(
    () => ({
      hasScrollAnimation,
      searchComponent,
      basketComponent,
    }),
    [hasScrollAnimation, searchComponent, basketComponent],
  )

  return (
    <NavigationContext.Provider value={context}>
      <SearchProvider onSubmit={onSearchSubmit}>
        <DrawerNavigation>{children}</DrawerNavigation>
      </SearchProvider>
    </NavigationContext.Provider>
  )
}

export const useNewNavigationContext = () => useContext(NavigationContext)
