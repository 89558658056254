import { useRemoveCartItems } from '@/features/cart/hooks/useRemoveCartItems'
import { useUpdateCart } from '@/features/cart/hooks/useUpdateCart'
import { Icon, IconButton } from '@lounge-fe/ui-kit'
import { useTranslation } from 'react-i18next'
import { BasketLineRemoveButtonProps } from './types'

export const RemoveButton = ({ lineItemKeys }: BasketLineRemoveButtonProps) => {
  const { t } = useTranslation()
  const removeItems = useRemoveCartItems()
  const update = useUpdateCart()

  return (
    <IconButton
      as="button"
      icon={<Icon.Trash width={16} height={16} />}
      aria-label={t('action.remove')}
      className="border-primary-default"
      disabled={removeItems.isPending || update.isPending}
      onClick={() =>
        removeItems.mutate(
          lineItemKeys.flatMap((key) => (Array.isArray(key) ? [...key] : key)),
        )
      }
    />
  )
}
