import { FormEventHandler, useCallback, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import {
  Input,
  Icon,
  Transition,
  CloseIconButton,
  usePopoverContext,
  Wrapper,
} from '@lounge-fe/ui-kit'
import { SearchFormProps } from '../types'
import { useNewSearchContext } from '../SearchProvider'

export const SearchFormMobile = ({
  onClose,
  showClearButton,
  className,
  focusOnMount = false,
}: SearchFormProps) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLInputElement | null>(null)
  const { search, setSearch, onClear } = useNewSearchContext()
  const { onClose: handlePopoverClose } = usePopoverContext()

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback((evt) => {
    evt.preventDefault()
  }, [])

  const handleChange: FormEventHandler<HTMLInputElement> = useCallback(
    (evt) => setSearch(evt.currentTarget.value),
    [],
  )

  useEffect(() => {
    if (focusOnMount) {
      ref.current?.focus()
    }
  }, [focusOnMount])

  const handleClose = () => {
    handlePopoverClose()
    onClear()
  }

  return (
    <Wrapper
      size="sm"
      className="px-5 bg-primary sticky z-50 top-0 left-0 right-0 py-2"
    >
      <form className="w-full border-b py-4" onSubmit={handleSubmit}>
        <Input
          className={classNames(
            'color-[#111111] w-full',
            String.raw`[&_.input-group\_\_adornment\-\-placement\-start]:pl-0`,
            String.raw`[&_.input-group\_\_adornment\-\-placement\-end]:pr-0`,
            String.raw`[&_.input-group\_\_input>input]:!py-0`,

            className,
          )}
          placeholder={t('action.search_placeholder')}
          color="ghost"
          aria-label={t('action.search_products')}
          id="search-products"
          ref={ref}
          startAdornment={
            <Icon.Search
              width={16}
              height={16}
              className="button--icon-full mr-2"
            />
          }
          endAdornment={
            <Transition.Fade show={showClearButton || Boolean(search)}>
              <CloseIconButton
                type="reset"
                aria-label={t('action.clear')}
                onClick={onClose ?? handleClose}
                size="md"
                className="!bg-transparent button--icon-full !h-4 !w-4"
              />
            </Transition.Fade>
          }
          value={search}
          onChange={handleChange}
        />
      </form>
    </Wrapper>
  )
}
