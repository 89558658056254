import classNames from 'classnames'
import { FC, HTMLAttributes } from 'react'

export const Footer: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'relative flex items-center justify-between px-6 py-4 border-t',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}
