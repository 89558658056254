import { useTranslation } from 'react-i18next'
import { PropsWithChildren } from 'react'
import classNames from 'classnames'

import { Text, TextVariant } from '../../components/Text'

export const Notice = ({
  children,
  color = 'light',
  size = 'md',
}: PropsWithChildren<{
  color?: 'light' | 'dark'
  size?: 'sm' | 'md' | 'lg'
}>) => {
  const { t } = useTranslation()

  const colorMap = {
    light: 'product-card__notice--color-light',
    dark: 'product-card__notice--color-dark',
  }

  const sizeToVariantMap: Record<'sm' | 'md' | 'lg', TextVariant> = {
    sm: 'body-sm',
    md: 'body-md',
    lg: 'body-lg',
  }

  const sizeMap = {
    sm: 'product-card__notice--size-small',
    md: 'product-card__notice--size-medium',
    lg: 'product-card__notice--size-large',
  }

  return (
    <div
      className={classNames(
        'product-card__notice',
        color && colorMap[color],
        size && sizeMap[size],
      )}
    >
      <Text
        className="font-regular capitalize leading-md"
        variant={sizeToVariantMap[size]}
      >
        {children}
      </Text>
    </div>
  )
}
