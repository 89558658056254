import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react'

import { CarouselContextState, CarouselProps } from './types'

const CarouselContext = createContext<CarouselContextState>({
  embla: undefined,
} as CarouselContextState)

export const Carousel: FC<CarouselProps> = ({
  embla,
  emblaRef,
  selectedSlideIndex: providedSelectedSlideIndex,
  children,
  transition = 'slide',
}) => {
  const viewportId = useId()
  const [dragging, setDragging] = useState(false)
  const [draggable, setDraggable] = useState(false)
  const [slidesInView, setSlidesInView] = useState<number[]>([])
  const [slides, setSlides] = useState<HTMLElement[]>([])
  const [selectedSlide, setSelectedSlide] = useState(0)
  const [canScrollNext, setCanScrollNext] = useState(false)
  const [canScrollPrev, setCanScrollPrev] = useState(false)

  const onInit = useCallback(() => {
    setDraggable(embla?.internalEngine().options.draggable ?? false)
  }, [embla])

  const onReInit = useCallback(() => {
    setSlidesInView(embla?.slidesInView() ?? [])
    setSelectedSlide(embla?.selectedScrollSnap() ?? 0)
    setSlides(embla?.slideNodes() ?? [])
    setCanScrollNext(embla?.canScrollNext() ?? false)
    setCanScrollPrev(embla?.canScrollPrev() ?? false)
  }, [embla])

  const onSelect = useCallback(() => {
    setDraggable(embla?.internalEngine().options.draggable ?? false)
    setSlidesInView(embla?.slidesInView() ?? [])
    setSelectedSlide(embla?.selectedScrollSnap() ?? 0)
    setSlides(embla?.slideNodes() ?? [])
    setCanScrollNext(embla?.canScrollNext() ?? false)
    setCanScrollPrev(embla?.canScrollPrev() ?? false)
  }, [embla])

  const onPointerUp = useCallback(() => {
    setDragging(false)
  }, [])

  const onPointerDown = useCallback(() => {
    setDragging(true)
  }, [])

  useEffect(() => {
    if (embla) {
      embla.on('init', onInit)
      embla.on('reInit', onReInit)
      embla.on('pointerUp', onPointerUp)
      embla.on('pointerDown', onPointerDown)
      embla.on('select', onSelect)

      onInit()
      onSelect()

      return () => {
        embla.off('init', onInit)
        embla.off('reInit', onReInit)
        embla.off('select', onSelect)
        embla.off('pointerUp', onPointerUp)
        embla.off('pointerDown', onPointerDown)
      }
    }
  }, [embla, onInit, onPointerUp, onPointerDown, onSelect])

  useEffect(() => {
    if (typeof providedSelectedSlideIndex !== 'undefined') {
      embla?.scrollTo(providedSelectedSlideIndex, true)
    }
  }, [embla, providedSelectedSlideIndex])

  const context = useMemo(
    () => ({
      emblaRef,
      embla,
      viewportId,
      dragging,
      draggable,
      slides,
      slidesInView,
      selectedSlide,
      canScrollNext,
      canScrollPrev,
      transition,
    }),
    [
      emblaRef,
      embla,
      viewportId,
      slides,
      slidesInView,
      selectedSlide,
      canScrollNext,
      canScrollPrev,
      draggable,
      dragging,
      transition,
    ],
  )

  return (
    <CarouselContext.Provider value={context}>
      {children}
    </CarouselContext.Provider>
  )
}

export const useCarouselContext = () => useContext(CarouselContext)

Carousel.displayName = 'Carousel'
