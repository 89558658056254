import type { InitParams } from 'search-insights/dist/init'
import { create } from 'zustand'
import { useShopify } from '../useShopify'
import {
  ClickedFiltersEvent,
  ClickedFiltersOptions,
  ClickedProductAfterSearchOptions,
  ConvertedObjectIDsAfterSearchOptions,
  UseSearchInsightsStore,
  ViewedProductEvent,
  ViewedProductOptions,
} from './types'

const searchInsights = import('search-insights')

const useSearchInsightsStore = create<UseSearchInsightsStore>((set, get) => ({
  hasInit: false,
  queue: [],
  init: () => {
    get().queue.forEach((fn) => fn())
    set({ hasInit: true, queue: [] })
  },
  addToQueue: (fn: () => unknown) => {
    if (get().hasInit) {
      fn()
    } else {
      set((state) => ({ queue: [...state.queue, fn] }))
    }
  },
}))

export const useSearchInsights = () => {
  const store = useSearchInsightsStore()
  const {
    integrations: { algolia },
  } = useShopify()

  const index = `${algolia.indexPrefix}_products`
  const { appId, apiKey } = algolia

  const init = async (args: InitParams) => {
    const aa = (await searchInsights).default
    aa('init', {
      appId,
      apiKey,
      useCookie: true,
      partial: false,
      ...args,
    })
    store.init()
  }

  const clickedProductAfterSearch = async ({
    objectID,
    eventName,
    queryID = '',
    position = 0,
  }: ClickedProductAfterSearchOptions) => {
    const aa = (await searchInsights).default
    store.addToQueue(() =>
      aa('clickedObjectIDsAfterSearch', {
        index,
        objectIDs: Array.isArray(objectID) ? objectID : [objectID],
        eventName: eventName.toString(),
        positions: [position],
        queryID,
      }),
    )
  }

  const clickedFilters = async ({
    eventName = ClickedFiltersEvent.Collections,
    ...args
  }: ClickedFiltersOptions) => {
    const aa = (await searchInsights).default
    store.addToQueue(() =>
      aa('clickedFilters', {
        index,
        eventName: eventName.toString(),
        ...args,
      }),
    )
  }

  const convertedObjectIDsAfterSearch = async ({
    objectID,
    eventName,
    ...args
  }: ConvertedObjectIDsAfterSearchOptions) => {
    const aa = (await searchInsights).default
    store.addToQueue(() =>
      aa('convertedObjectIDsAfterSearch', {
        index,
        objectIDs: Array.isArray(objectID) ? objectID : [objectID],
        eventName: eventName.toString(),
        ...args,
      }),
    )
  }

  const setUserToken = async (userToken: string) => {
    const aa = (await searchInsights).default
    store.addToQueue(() => aa('setUserToken', userToken))
  }

  const viewedProduct = async ({
    objectID,
    eventName = ViewedProductEvent.ProductPage,
  }: ViewedProductOptions) => {
    const aa = (await searchInsights).default
    store.addToQueue(() =>
      aa('viewedObjectIDs', {
        index,
        objectIDs: Array.isArray(objectID) ? objectID : [objectID],
        eventName: eventName.toString(),
      }),
    )
  }

  return {
    init,
    clickedProductAfterSearch,
    clickedFilters,
    convertedObjectIDsAfterSearch,
    setUserToken,
    viewedProduct,
  }
}
