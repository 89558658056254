import React from 'react'
import { useDrawerNavigationContext } from '../DrawerNavigation'
import { Button, Tile, Transition, useMediaQuery } from '@lounge-fe/ui-kit'
import { SubNavigationTilesProps } from './types'
import { SubNavigationTileGrid } from './SubNavigationTileGrid'

export const SubNavigationTiles: React.FC<SubNavigationTilesProps> = ({
  tiles,
  showTiles,
}) => {
  const isMobile = useMediaQuery(({ screens }) => `(max-width: ${screens.sm})`)
  const isTablet = useMediaQuery(({ screens }) => `(max-width: ${screens.md})`)
  const { size } = useDrawerNavigationContext()

  // Only render a tile if there is an image and it should be shown
  if (
    !tiles.some(
      ({ image, desktop_image, show_desktop, show_mobile }) =>
        image && desktop_image && (show_mobile || show_desktop),
    )
  ) {
    return null
  }

  if (isTablet && !isMobile && (size === 'lg' || size === 'xl')) {
    return <SubNavigationTileGrid showTiles={showTiles} tiles={tiles} />
  }

  const mobileTile = tiles.find(({ show_mobile }) => show_mobile)
  const desktopTile = tiles.find(({ show_desktop }) => show_desktop)

  const mobileImage = mobileTile?.image
    ? {
        src: mobileTile.image.src,
        width: 350,
        height: 350,
      }
    : undefined

  const desktopImage = desktopTile?.desktop_image
    ? {
        src: desktopTile.desktop_image.src,
        width: 415,
        height: 700,
      }
    : undefined

  const activeTile = isMobile ? mobileTile : desktopTile

  const showButton = activeTile?.button
  const image = (isMobile ? mobileImage : desktopImage) ?? mobileImage
  const showImage = activeTile?.image?.src

  return activeTile ? (
    <Transition.Fade show={showTiles} className="mt-8 sm:mt-0">
      <Tile
        as="a"
        className="block"
        href={activeTile.link}
        size="md"
        contentPlacement="inside"
        overlay={activeTile.overlay}
      >
        {showImage && image?.src && (
          <Tile.Image
            className="aspect-[1/1] sm:aspect-[unset] sm:min-h-full sm:h-[calc(100dvh-10rem-1px)] sm:w-full sm:max-w-full sm:max-h-[678px]"
            data={{
              src: image.src,
              width: image.width,
              height: image.height,
              crop: 'center',
            }}
          />
        )}
        <Tile.Content>
          <Tile.Title>{activeTile.title}</Tile.Title>
          {activeTile.subtitle && (
            <Tile.Subtitle>{activeTile.subtitle}</Tile.Subtitle>
          )}

          {showButton && (
            <Tile.Actions>
              <Button variant="solid" color="secondary" size="sm" tabIndex={-1}>
                {activeTile.button}
              </Button>
            </Tile.Actions>
          )}
        </Tile.Content>
      </Tile>
    </Transition.Fade>
  ) : undefined
}
