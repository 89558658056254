'use client'

import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Icon } from '../Icon'

import { NotificationProps, NotificationSeverity } from './types'

const severityClassMap: {
  [key in Exclude<NotificationSeverity, 'default'>]: string
} = {
  info: 'notification--info',
  warning: 'notification--warning',
  critical: 'notification--critical',
  success: 'notification--success',
}

export const Notification = ({
  severity = 'default',
  className,
  children,
  onClose,
  title,
  ...props
}: NotificationProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div
      role="alert"
      className={classNames(
        'notification',
        severity !== 'default' && severityClassMap[severity],
        className,
      )}
      {...props}
    >
      <Icon.Information className="notification__icon" />
      <span>
        {title && <b>{title} </b>}
        {children}
      </span>
      {onClose && (
        <button
          className="notification__close"
          aria-label={t('action.close') ?? undefined}
        >
          <Icon.Close />
        </button>
      )}
    </div>
  )
}
