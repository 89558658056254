import classNames from 'classnames'

import { ToggleGroup } from '../ToggleGroup'
import { SwatchesItemProps } from './types'

export const Item = ({
  className,
  children,
  color,
  ...props
}: SwatchesItemProps): JSX.Element => {
  return (
    <ToggleGroup.Item
      className={classNames('swatches__item', className)}
      {...props}
    >
      {(bag) => (
        <>
          <span
            className={classNames(
              'swatches__color',
              bag.selected && 'swatches--selected',
            )}
            style={{ backgroundColor: color }}
          ></span>
          <span className="swatches__label">
            {typeof children === 'function' ? children(bag) : children}
          </span>
        </>
      )}
    </ToggleGroup.Item>
  )
}
