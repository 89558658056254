import { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import useEmblaCarousel from 'embla-carousel-react'

import { ShopifySectionComponent } from '@/types/ShopifySectionComponent'
import {
  Button,
  Carousel,
  Image,
  Link,
  Text,
  Wrapper,
  useMediaQuery,
} from '@lounge-fe/ui-kit'

import { MediaContentSliderData } from './types'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'

export const MediaContentSlider: ShopifySectionComponent<
  MediaContentSliderData
> = ({ data }) => {
  const { title, slides } = data!

  const isDesktop = useMediaQuery(({ screens }) => `(min-width: ${screens.sm})`)

  const active = isDesktop ? '7/5' : '3/2'
  const notActive = isDesktop ? '7/4' : '5/3'

  const [emblaRef, embla] = useEmblaCarousel(
    {
      align: 'center',
      containScroll: 'trimSnaps',
      skipSnaps: true,
      draggable: true,
      loop: true,
      speed: 10,
      inViewThreshold: isDesktop ? 0.1 : 0,
      startIndex: 1,
    },
    [WheelGesturesPlugin()],
  )

  const [activeIndex, setActiveIndex] = useState(1)

  const onScroll = () => {
    const index = embla?.selectedScrollSnap()
    setActiveIndex(index ?? 1)
  }

  embla?.on('scroll', onScroll)

  return (
    <div className="py-6">
      <Carousel emblaRef={emblaRef} embla={embla}>
        {title && (
          <Wrapper>
            <Text className="mb-6 md:mb-8" variant="title-five">
              {title}
            </Text>
          </Wrapper>
        )}
        <Carousel.Viewport>
          <Carousel.Container className="auto-cols-[calc(90%-16px)] md:auto-cols-[756px]">
            {slides &&
              slides.map(
                (
                  { image, title, text, buttonText, buttonLink, buttonVariant },
                  idx,
                ) => (
                  <Carousel.Slide
                    key={idx}
                    index={idx}
                    className="px-3 md:px-5"
                  >
                    <motion.div
                      layout
                      initial={{ height: notActive }}
                      animate={{
                        aspectRatio: idx === activeIndex ? active : notActive,
                      }}
                      transition={{
                        damping: 70,
                        stiffness: 120,
                        mass: 0.3,
                        duration: 0.5,
                        ease: 'easeInOut',
                      }}
                    >
                      <Image
                        data={{
                          src: image.src,
                          width: 700,
                          height: 500,
                          crop: 'center',
                        }}
                        alt={image.alt}
                        className="object-cover h-full w-full"
                      />
                    </motion.div>
                    <div className="md:max-w-[472px] md:mx-auto">
                      <Text variant="title-five" className="text-center pt-6">
                        {title}
                      </Text>
                      <Text variant="body-sm" className="text-center pt-2">
                        {text}
                      </Text>
                      {buttonText && buttonLink && (
                        <div className="flex justify-center pt-3">
                          {buttonVariant === 'primary' && (
                            <Button as="a" href={buttonLink} color="primary">
                              {buttonText}
                            </Button>
                          )}
                          {buttonVariant === 'link' && (
                            <Link href={buttonLink} className="underline">
                              {buttonText}
                            </Link>
                          )}
                        </div>
                      )}
                    </div>
                  </Carousel.Slide>
                ),
              )}
          </Carousel.Container>
        </Carousel.Viewport>
      </Carousel>
    </div>
  )
}

export default MediaContentSlider
