import React from 'react'

import { SvgProps } from '../types'
import { Icon } from '../Icon'

export const Heart: React.FC<SvgProps> = (props) => {
  return (
    <Icon viewBox="0 0 20 20" fillRule="evenodd" clipRule="evenodd" {...props}>
      <path
        d="M10.0058 17.6897L2.18531 10.606C-2.06499 6.35565 4.18294 -1.80491 10.0058 4.79721C15.8287 -1.80491 22.0484 6.38399 17.8264 10.606L10.0058 17.6897Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}
