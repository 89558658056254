import { PropsWithChildren } from 'react'
import { Link, Modal } from '@lounge-fe/ui-kit'
import { useTranslation } from 'react-i18next'
import { TriggerProps } from './types'

export const LinkTrigger = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation()
  return (
    <Modal.Trigger>
      <Link
        as="button"
        variant="body-sm"
        className="underline h-10 text-subtle"
      >
        {children}
      </Link>
    </Modal.Trigger>
  )
}
