import { ElementType } from 'react'
import classNames from 'classnames'

import { Box } from '../Box'

import { TextProps, TextVariant } from './types'

const variantClass: { [key in TextVariant]: string } = {
  display: 'typography--display',
  'title-one': 'typography--title-one',
  'title-two': 'typography--title-two',
  'title-three': 'typography--title-three',
  'title-four': 'typography--title-four',
  'title-five': 'typography--title-five',
  'title-six': 'typography--title-six',
  'body-lg': 'typography--body-lg',
  'body-md': 'typography--body-md',
  'body-sm': 'typography--body-sm',
  'body-xs': 'typography--body-xs',
}

const defaultElement = 'p'

export const Text = <TElement extends ElementType = typeof defaultElement>({
  children,
  as,
  variant = 'body-md',
  className,
  ...props
}: TextProps<TElement>) => {
  const element: React.ElementType = as || defaultElement
  return (
    <Box
      as={element}
      className={classNames('typography', variantClass[variant], className)}
      {...props}
    >
      {children}
    </Box>
  )
}
