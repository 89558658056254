import { useQuery } from '@tanstack/react-query'

import { UseVacanciesQueryOptions, UseVacanciesQueryResult } from './types'
import { useService } from '@/hooks/useService'
import { CareersService } from '../../services/CareersService'
import { useEffect, useState } from 'react'
import {
  addJobCountToDepartments,
  filterJobsByDepartments,
  mapJobsToArticles,
} from './helpers'

/**
 * useJobs is a custom hook that fetches vacancies data
 * from the Greenhouse API and returns it in a paginated manner.
 *
 * @param {object} parameters - The parameters for the vacancies query.
 * @param {number} parameters.targetPageSize - The target number of items per page. Defaults to 6.
 * @param {number[]} parameters.filterDepartments - The department IDs to filter vacancies by.
 * @param {object} options - Additional options for the useQuery hook.
 *
 * @returns {object} The result of the useInfiniteQuery hook.
 */
export const useJobs = ({
  targetPageSize = 6,
  filterDepartments,
  articles,
  ...queryOptions
}: UseVacanciesQueryOptions) => {
  const [endCursor, setEndCursor] = useState(targetPageSize)
  const careers = useService(new CareersService())

  useEffect(() => {
    if (filterDepartments && filterDepartments.length > 0) {
      setEndCursor(targetPageSize)
    }
  }, [filterDepartments])

  // Get vacancies from Greenhouse API
  const result = useQuery<UseVacanciesQueryResult>({
    queryKey: ['jobs'],
    queryFn: async () => {
      const { data } = await careers.getJobs()
      const jobs = mapJobsToArticles(data.jobs, articles)
      const departments = addJobCountToDepartments(jobs, data.departments)
      const total = jobs.length
      return { jobs, departments, total }
    },
    ...queryOptions,
  })

  const filteredJobs = filterJobsByDepartments(
    result.data?.jobs ?? [],
    filterDepartments,
  )

  const paginatedJobs = filteredJobs.slice(0, endCursor) ?? []

  const locations = new Set<string>()

  filteredJobs.forEach((job) => {
    if (job.location?.name) {
      locations.add(job.location.name)
    }
  })

  return {
    ...result,
    data: {
      jobs: paginatedJobs,
      departments: result.data?.departments,
      total: filteredJobs.length,
      locations: Array.from(locations),
    },
    loadMore: () => setEndCursor(endCursor + targetPageSize),
    hasNextPage: paginatedJobs.length < filteredJobs.length,
  }
}
