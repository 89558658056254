import { Department, Job } from '../../services/CareersService'
import { Article } from '../../components/Vacancies/types'
import { UseVacanciesQueryResult } from './types'

export const findJobById = (jobs: Job[], id: string) =>
  jobs.find((job) => job.id.toString() === id)

export const mapJobsToArticles = (jobs: Job[], articles: Article[]) =>
  articles
    .map(({ id, url }) => {
      const job = findJobById(jobs, id)
      return job ? { ...job, url } : undefined
    })
    .filter(Boolean) as UseVacanciesQueryResult['jobs']

export const addJobCountToDepartments = <TJob extends Job>(
  jobs: TJob[],
  departments: Department[],
) =>
  departments.map((department) => ({
    ...department,
    count: jobs.reduce(
      (prev, curr) =>
        prev +
        curr.departments.reduce(
          (prev, curr) => prev + (curr.id === department.id ? 1 : 0),
          0,
        ),
      0,
    ),
  }))

export const filterJobsByDepartments = <TJob extends Job>(
  jobs: TJob[],
  departmentIds: number[],
) => {
  // No department filter, return all jobs
  if (!departmentIds.length) {
    return jobs
  }

  return jobs.filter((job) =>
    job.departments.some((department) => departmentIds.includes(department.id)),
  )
}
