import { createContext, FC, useContext, useMemo } from 'react'

import { useTimer } from '@lounge-fe/ui-kit'
import { useRecentlyAddedProducts } from '../../hooks/useRecentlyAddedProducts'

import { CartContextProps, CartContextState } from './types'

const CartContext = createContext<CartContextState>({} as CartContextState)

export const CartProvider: FC<CartContextProps> = ({
  children,
  cartManager,
}) => {
  const setRecentlyAddedProductIds = useRecentlyAddedProducts(
    ({ setRecentlyAddedProductIds }) => setRecentlyAddedProductIds,
  )

  const recentlyAddedTimer = useTimer(
    () => setRecentlyAddedProductIds([]),
    3000,
  )

  const context = useMemo(
    () => ({
      cartManager,
      recentlyAddedTimer,
    }),
    [],
  )

  return <CartContext.Provider value={context}>{children}</CartContext.Provider>
}

export const useCartContext = () => useContext(CartContext)
