import { createCompoundComponent, Drawer } from '@lounge-fe/ui-kit'
import { DrawerNavigation as BaseDrawerNavigation } from './DrawerNavigation'
import { Link } from './Link'
import { Content } from './Content'

export const DrawerNavigation = createCompoundComponent(BaseDrawerNavigation, {
  Content,
  Drawer,
  Link,
})
