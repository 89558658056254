import { ToggleGroup as RootToggleGroup } from './ToggleGroup'
import { Item } from './Item'

export type {
  ToggleGroupProps,
  ToggleGroupItemProps,
  sizeVariant,
} from './types'

export const ToggleGroup = Object.assign(RootToggleGroup, { Item })
