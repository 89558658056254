import {
  FormEventHandler,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  CloseIconButton,
  Icon,
  IconButton,
  Input,
  usePopoverContext,
  Wrapper,
} from '@lounge-fe/ui-kit'
import { useNewSearchContext } from '../SearchProvider'
import classNames from 'classnames'

export const SearchForm = forwardRef<HTMLInputElement>((_, ref) => {
  const { t } = useTranslation()
  const { search, onClear, setSearch } = useNewSearchContext()
  const { onClose } = usePopoverContext()

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback((evt) => {
    evt.preventDefault()
  }, [])

  const handleChange: FormEventHandler<HTMLInputElement> = useCallback(
    (evt) => setSearch(evt.currentTarget.value),
    [],
  )

  return (
    <div className="desktop-search w-full bg-primary z-50 sticky top-0 left-0 right-0 py-4">
      <Wrapper className="h-full w-screen max-w-full px-6 2xl:px-12 pl-4">
        <form
          className="flex items-center w-full gap-4 border-b py-4"
          onSubmit={handleSubmit}
        >
          <Input
            className={classNames(
              'w-full',
              String.raw`[&_.input-group\_\_adornment\-\-placement\-start]:pl-0`,
              String.raw`[&_.input-group\_\_input>input]:!py-0`,
            )}
            placeholder={t('action.search_placeholder')}
            color="ghost"
            aria-label={t('action.search')}
            id="search-products"
            name="search-products"
            value={search}
            onChange={handleChange}
            startAdornment={
              <IconButton
                aria-label="Search"
                className="!bg-transparent button--icon-full !h-5 !w-5 mr-2 flex items-center"
                icon={<Icon.Search />}
              />
            }
            ref={ref}
          />
          <CloseIconButton
            type="reset"
            aria-label={t('action.clear')}
            onClick={() => {
              onClose()
              onClear()
            }}
            size="md"
            className="!bg-transparent button--icon-full !h-3 !w-3"
          />
        </form>
      </Wrapper>
    </div>
  )
})
