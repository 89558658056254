import classNames from 'classnames'
import { HorizontalGaugeProps } from './types'

const HorizontalGauge: React.FC<HorizontalGaugeProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <section
      {...props}
      className={classNames('flex w-full min-w-full', className)}
    >
      {children}
    </section>
  )
}

export { HorizontalGauge }
