import { Search } from '../Search'
import { useSearchContext } from '../Navigation/SearchProvider'
import { useNewSearchContext } from '../NewNavigation/SearchProvider'
import { RecommendationProps } from './types'
import { Text } from '@lounge-fe/ui-kit'
import { useTranslation } from 'react-i18next'

export const Recommendations = ({
  suggestedSearchTerms,
}: RecommendationProps) => {
  const { t } = useTranslation()

  const isNewNavigationEnabled = true

  const searchContext = useSearchContext()
  const newSearchContext = useNewSearchContext()

  const { setSearch } = isNewNavigationEnabled
    ? newSearchContext
    : searchContext

  if (!suggestedSearchTerms?.length) return

  return (
    <>
      <Text
        as="h2"
        variant="body-xs"
        className=" mb-3 uppercase text-[#888888]"
      >
        {t('general.search_suggestions')}
      </Text>
      <div className="flex flex-col gap-3 items-start">
        {suggestedSearchTerms.map((term) => {
          return (
            <Search.MenuItem
              key={term}
              onClick={() => setSearch(term.toLowerCase())}
            >
              {term}
            </Search.MenuItem>
          )
        })}
      </div>
    </>
  )
}
