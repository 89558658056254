import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { ContentSize, DrawerNavigationContextState } from './types'

const DrawerNavigationContext = createContext<DrawerNavigationContextState>(
  {} as DrawerNavigationContextState,
)

export const DrawerNavigation: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [activeItem, setActiveItem] = useState<string | undefined>(undefined)
  const [size, setSize] = useState<ContentSize>('sm')

  const resetDrawer = useCallback(() => {
    setActiveItem(undefined)
    setSize('sm')
  }, [])

  const context = useMemo<DrawerNavigationContextState>(
    () => ({
      activeItem,
      setActiveItem,
      size,
      setSize,
      resetDrawer,
    }),
    [activeItem, size],
  )

  return (
    <DrawerNavigationContext.Provider value={context}>
      {children}
    </DrawerNavigationContext.Provider>
  )
}

export const useDrawerNavigationContext = () =>
  useContext(DrawerNavigationContext)
