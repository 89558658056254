import * as Sentry from '@sentry/react'

import { Fragment } from 'react'
import { Money } from '@shopify/hydrogen-react'

import { useShopify } from '../../../../hooks/useShopify'
import { StandardProductCardPriceProps } from './types'

export const Price = Sentry.withErrorBoundary(
  ({ price }: StandardProductCardPriceProps) => {
    const { currency } = useShopify()
    return (
      <Money
        data={{
          amount: (Number(price) / 100).toString(),
          currencyCode: currency.active,
        }}
        as={Fragment}
      />
    )
  },
  { fallback: <Fragment /> },
)
